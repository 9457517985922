import {Container, Nav, Navbar, NavbarBrand} from "react-bootstrap";
import {Images} from "../assets/Images";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import {scrollTo} from "../Helpers/Helpers";

export const Header = () => {
	return (
		<Navbar expand="lg" className={'shadow-sm'} style={{backgroundImage: 'white', background: '#fff'}}>
			<Container>
				<NavbarBrand>
					<img alt='logo' src={Images.ibramiLogo} style={{width: 240}}/>
				</NavbarBrand>

				<Navbar.Toggle aria-controls="basic-navbar-nav"/>
				<NavbarCollapse id='basic-navbar-nav' style={{flexGrow: "initial"}}>
					<Nav className='align-items-end align-items-lg-center fs-5 header-options divider-header'>
						<Nav.Link onClick={() => scrollTo('home')}
								  className='text-white text-uppercase color-primary'> Início </Nav.Link>
						<div className='d-none d-lg-block'/>
						<Nav.Link onClick={() => scrollTo('about')}
								  className='text-white text-uppercase color-primary'> Sobre nós </Nav.Link>
						<div className='d-none d-lg-block'/>
						<Nav.Link onClick={() => scrollTo('themes')}
								  className='text-white text-uppercase color-primary'> Conteúdos </Nav.Link>
						<div className='d-none d-lg-block'/>
						<Nav.Link onClick={() => scrollTo('contact')}
								  className='text-white text-uppercase color-primary'> Contato </Nav.Link>
						<div className='d-none d-lg-block'/>
						<Nav.Link onClick={() => scrollTo('monthly')}
								  className='text-white text-uppercase color-primary'> Mensalidade </Nav.Link>
					</Nav>
				</NavbarCollapse>
			</Container>
		</Navbar>
	)
}