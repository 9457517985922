import React, {useEffect, useState} from "react";
import {Container, Image, Navbar, NavbarBrand} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {Images} from "../../assets/Images";
import {BsFillQuestionCircleFill} from "react-icons/bs";
import {maskReal, scrollTo} from "../../Helpers/Helpers";
import axios from "axios";
import SendDocsStep from "../SendDocsFull/Components/SendDocsStep";
import stepImage from "../../assets/images/endStep.png";
import ImageButtonComponent from "../../components/ImageButtonComponent";
import DataConfirmationStep from "../SendDocsFull/Components/DataConfirmationStep";
import SEO from "../../DefaultLayout/SEO";
import congrats from "../../assets/images/congrats.png";
import {toast} from "react-toastify";
import {FaPaperclip} from "react-icons/fa";

const AttendantSendDocsPage = () => {
    const [step, setStep] = useState(0);
    const [dataUser, setDataUser] = useState({})
    const params = useParams()
    const [src, setSrc] = useState();

    const getSrc = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('src');
        setSrc(myParam);
    }
    const handleFinishForm = async (e) => {

        let {registration: {affiliated:{person: {contact_mobile_phone,address_cep, address_city_id, address_street, address_number, address_complement, address_district, natural_nationality, natural_civil_status_id, natural_gender, document_front, document_back, document_selfie
        ,state_id
        }}}} = dataUser
        let sendToApi = {
            src: src,
            contact_mobile_phone: contact_mobile_phone,
            address_cep: address_cep,
            state_id: state_id,
            address_city_id: address_city_id,
            address_street: address_street,
            address_number: address_number,
            address_complement: address_complement,
            address_district: address_district,
            natural_nationality: natural_nationality,
            natural_civil_status_id: natural_civil_status_id,
            natural_gender: natural_gender,
            document_front: document_front,
            document_back: document_back,
            document_selfie: document_selfie,
            ...e
        }

        await axios.post(`inss-calc/registration/${params.hash}`, sendToApi).then((res) => {
        }).catch((e) => console.log(e))

        setStep( step + 1)

        handleSaveForm(e)
    }

    const handleSaveForm = (e) => {
        let aux = {
            ...dataUser,
        }
        aux.registration.affiliated.person = {
            ...aux.registration.affiliated.person,
            ...e
        }
        setDataUser(aux)
    }

    const copyDesktop = () => {
        navigator.clipboard.writeText(`https://ibrami.org/revisao-vida-toda/${params?.hash}`);
        toast.success('Copiado para a área de transferência!');
    };

    const getData = async () => {
        try {
            let {data: {object}} = await axios.get(`inss-calc/registration/${params?.hash}`)

            let {registration:{affiliated}} = object

            setDataUser(object)
        } catch (e) {
            let { response } = e
            if (response.data.message === 'Registro já foi finalizado') {
                setStep(2)
                toast.success('Registro já foi finalizado')
            }
            console.log(e)
        }
    }

    useEffect(() => {
        getData();
        getSrc();
        scrollTo('full-steps')
    }, []);


    return (
        <>
            <SEO title='Atendente'
                 description='Atendente'
                 name='Atendente'
                 type='article'
            />
            <Navbar expand="lg" className={'shadow-sm'} style={{backgroundImage: 'white', background: '#fff'}}>
                <Container>
                    <NavbarBrand>
                        <Link to={'/'}>
                            <img alt='logo' src={Images.ibramiLogo} style={{width: 240}}/>
                        </Link>

                    </NavbarBrand>
                </Container>
            </Navbar>
            <div className="container my-5" id={'full-steps'}>

                <div className={'text-gray-600'}>
                    <h1 className={'text-ibrami-primary '}>Envio de dados e documentos cadastrais</h1>
                    <p className={'text-muted ps-2'}>Cadastro para participar da ação coletiva do IBRAMI</p>
                </div>
                    <>
                        <div className="shadow rounded custom-progress" >
                            <div>
                                {step === 0 &&
                                    <>
                                        <div className="d-flex flex-grow-1 p-4 flex-column align-items-center w-100 border-bottom">



                                            <div className="text-center my-3">
                                                <h2 className={`text-muted mb-2`}> Parabéns, <strong className={'text-ibrami-secondary'}>{dataUser?.registration?.affiliated?.person.name}</strong> </h2>
                                                <div className="w-100 d-flex align-items-center p-1 justify-content-center">
                                                    <Image src={congrats} style={{maxHeight: 300, borderRadius: 20, width: '100%', objectFit: 'contain'}} />
                                                </div>
                                                <h4 className='text-muted'>
                                                    Você é elegível para um possível aumento de aposentadoria de <span style={{fontWeight: 'bold'}} className={'text-ibrami-secondary'}>R${maskReal(dataUser?.registration?.calculate.wage_original)}</span> para <span style={{fontWeight: 'bold'}} className={'text-ibrami-secondary'}>R${maskReal(dataUser?.registration?.calculate.last_wage)}</span>
                                                </h4>
                                                <h3 className=''>
                                                    E ainda poderá receber <span style={{fontWeight: 'bold'}} className={'text-ibrami-secondary'}>R${maskReal(dataUser?.registration?.calculate.total_procedural)}</span> de retroativos*
                                                </h3>
                                                <span style={{textAlign: 'center', color: 'gray', fontSize: 15,marginTop: 10}}>* Cálculo baseado nas informações contidas no CNIS e Carta de Concessão enviadas</span>
                                                <br/>
                                                <span style={{textAlign: 'center', color: 'gray', fontSize: 15,marginTop: 10}}>* O valor exato da causa dependerá do cálculo utilizado durante o julgamento da ação</span>
                                                {dataUser?.registration?.calculate?.note &&
                                                    <div className={`mt-4`}>
                                                        <h6 className={'text-start'}>Observação:</h6>
                                                        <textarea className={'form-control'} disabled value={dataUser?.registration?.calculate?.note}/>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                        <DataConfirmationStep person={dataUser?.registration?.affiliated?.person}
                                                              affiliated={dataUser?.registration?.affiliated?.person.name}
                                                              phone={dataUser?.registration?.affiliated?.person.contact_mobile_phone}
                                                              setStep={(e) => setStep(step + 1)}
                                                              handleSaveForm={(e) => handleFinishForm(e)}
                                        />
                                    </>

                                }
                                {step === 1 &&
                                    <SendDocsStep handleFinishForm={(e) => handleFinishForm(e)}
                                                  affiliated={dataUser?.registration?.affiliated?.person.name}
                                                  phone={dataUser?.registration?.affiliated?.person.contact_mobile_phone}
                                                  setStep={() => {}}/>
                                }
                                {step === 2 && <ImageButtonComponent buttonColor={'success'} titleColor={'success'} title={'Tudo certo!'}
                                                                     description={<div>
                                                                         <span>Agora informe ao cliente para acessar o LINK e realizar a confirmação do Cadastro e tirar uma Selfie para Assinar</span> <br/>
                                                                         <a href={'https://ibrami.org/revisao-vida-toda/'+params?.hash} target={'_blank'}>https://ibrami.org/revisao-vida-toda/{params?.hash}</a>
                                                                         <div className={`d-flex justify-content-center mt-4`}>
                                                                             <button className={'btn-reset btn-ibrami-primary'} onClick={copyDesktop}><FaPaperclip/> Copiar Link</button>
                                                                         </div>
                                                                     </div>}
                                                                     image={stepImage}/>}


                            </div>
                        </div>
                    </>
            </div>
        </>

    );
};

export default AttendantSendDocsPage;
