import React, {useEffect, useState} from "react";
import {Container, Navbar, NavbarBrand} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Images} from "../../assets/Images";
import refer from "../../assets/images/refer.png";
import {ErrorMessageComponent} from "../../components/ErrorMessageComponent";
import {Controller, useForm} from "react-hook-form";
import NumberFormat from "react-number-format";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import stepImage from "../../assets/images/Launching-amico.svg";
import ImageButtonComponent from "../../components/ImageButtonComponent";
import axios from "axios";
import {scrollTo} from "../../Helpers/Helpers";
import SEO from "../../DefaultLayout/SEO";

const RemoveAccountPage = () => {


    return (
        <>

            <div className="container my-5" id={'full-steps'}>


                <div className={'text-gray-600'}>
                    <h1 className={'text-ibrami-primary '}>Precisa remover sua Conta IBRAMI? </h1>
                    <p className={'text-muted ps-2'}>Ao remover sua conta, você perderá todo acesso ao aplicativo, caso precise você pode criar outra conta novamente</p>
                </div>


                <div className={`card shadow`}>
                    <div className="card-body">
                        <ImageButtonComponent title={'Passo a Passo'}
                                              description={"Acesse o aplicativo, no canto superior direito toque em MENU, dentro do MENU toque em 'Excluir minha Conta' e insira sua senha."}
                                              image={Images.remove}
                                              extra={<div className="border-top pt-4 mt-4 w-100 text-center">
                                                  <span style={{fontSize: 13, color: "gray"}}> Dúvidas? <strong className="text-secondary"> Fale conosco </strong></span>
                                              </div>}
                        />
                    </div>
                </div>

            </div>
        </>

    );
};

export default RemoveAccountPage;
