import React, { useEffect, useState, useContext } from "react";
import {Button, Row, Container, Image, ProgressBar, Form} from "react-bootstrap";


const ImageButtonComponent = (props) => {
    const [step, setStep] = useState(0);

    return (

        <div className="d-flex flex-grow-1 p-4 flex-column align-items-center w-100">

            <div className="w-100 d-flex align-items-center p-1 justify-content-center">
                <Image src={props.image} style={{maxHeight: props.maxHeight || 200, borderRadius: 20, width: '100%', objectFit: 'contain'}} />
            </div>

            <div className="text-center my-3">
                {props.title &&<h2 className={`text-${props.titleColor || 'ibrami-primary'} mb-2`}> {props.title} </h2>}
                <span  className='text-muted' style={{fontSize: 15}}>
                   {props.description}
                </span>
            </div>
            {props.buttonText &&
                <div className={`row w-100 ${props.secondButtonText ? 'justify-content-between' : 'justify-content-center'}`}>
                    {props.secondButtonText && <button type="button" onClick={() => props.secondButtonOnClick()} className="m-auto mt-4 px-3 btn rounded-pill">{props.secondButtonText}</button>}
                    <button type="button"
                            onClick={() => props.onClick()}
                            className={`col-12 col-md-6 bg-ibrami-primary bg-opacity-90-hover btn-reset p-2 fs-1 rounded-pill text-white`}>
                        {props.buttonText}
                    </button>
                </div>}
            {props.extra && props.extra}
        </div>
    );
};

export default ImageButtonComponent;
