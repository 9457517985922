import {Button, Col, Container, Nav, Navbar, NavbarBrand} from "react-bootstrap";
import {Images} from "../assets/Images";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Slider from "react-slick";
import Carousel from 'react-bootstrap/Carousel';
import {
    FaInstagram,
    FaFacebookF,
    FaAngleUp,
    FaApple,
    FaWhatsapp,
    FaChevronRight, FaRocket, FaHandsHelping
} from 'react-icons/fa'
import {BsAt, BsAwardFill, BsBoxArrowUpRight, BsFillEyeFill, BsTelephoneOutboundFill} from "react-icons/bs";
import {Fade, Flip} from 'react-reveal';
import {Link} from "react-router-dom";
import {Header} from "../DefaultLayout/Header";
import NumberFormat from "react-number-format";
import React, {useState} from "react";
import SEO from "../DefaultLayout/SEO";
import { BsReceipt } from "react-icons/bs";

const HomePage = () => {

    const NextArrow = ({onClick}) => {
        return <div onClick={onClick}>
            <i className="icofont-rounded-right" style={{fontSize: 28, display: "none"}}/>
        </div>

    }

    const PrevArrow = ({onClick}) => {
        return <div onClick={onClick}>
            <i className="icofont-rounded-left" style={{fontSize: 28, display: "none"}}/>
        </div>
    }

    const settings = {
        autoplay: true,
        infinite: true,
        adaptiveHeight: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        speed: 1000,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        autoplaySpeed: 10000,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: true,
                    centerPadding: 0,
                    centerMode: true,

                }
            }
        ]

    };
    const scrollTo = (id) => {
        document.getElementById(id).scrollIntoView({behavior: 'smooth'})
    }
    return (
        <>
            <SEO title='Home | Ibrami'
                 description='Página inicial'
                 name='Página inicial'
                 type='article'
            />
            <div id='home'/>

            <Header/>

            <Fade right delay={1000}>
                <Slider {...settings}>

                    <div>
                          <span>
                            <img src={Images.old5} className='position-relative bg-header-config-1 opacity-75'
                                 alt={''}/>

                            <span className='curtain col-12 col-md-8 text-white'>

                              <Fade left delay={1500}>
                                <span className='-weight '> Revisão de <span
                                    className='color-primary -weight contour'>APOSENTADORIA</span></span>
                                  <span className='sub-title lato'>
                                      Se você deseja saber se tem direito à Revisão da Vida Toda do INSS, o IBRAMI está aqui para ajudar. Faça agora o cálculo e descubra.
                                  </span>
                              </Fade>
                            </span>
                          </span>
                    </div>

                    <div>
                      <span>
                        <img src={Images.old10} className='position-relative bg-header-config-1 obj-pos-0 opacity-75'
                             alt={''}/>

                        <span className='curtain col-12 col-md-8 text-white'>
                          <Fade top delay={7000}>
                            <span className='-weight'>Plataforma <span
                                className='color-primary -weight contour'>DIGITAL</span></span>
                            <span className='sub-title lato'>Acreditamos que a tecnologia abre as portas para um novo mundo de possibilidades!</span>
                          </Fade>
                        </span>
                      </span>
                    </div>

                    <div>
                        <span>
                            <img src={Images.old1} className='position-relative bg-header-config-1 obj-pos-7 opacity-75'
                                 alt={''}/>

                            <span className='curtain col-12 col-md-8 text-white'>
                              <Fade right delay={12500}>
                                <span className='-weight'>Aqui no <span
                                    className='color-primary -weight contour'>IBRAMI</span></span>
                                <span className='sub-title lato'>Você é o protagonista da sua nova etapa de vida!</span>
                              </Fade>
                            </span>
                        </span>
                    </div>
                </Slider>
            </Fade>

            <div id='about' className='bg-color section-padding'>
                <div className='container d-sm-flex justify-content-sm-between'>
                    <div
                        className='d-flex justify-content-center justify-content-sm-start align-items-sm-center col-12 col-sm-4 col-lg-3'>
                        <Flip right cascade>
                            <div className='col-12 col-sm-6 p-1 p-md-5 flex-grow-1 text-center text-md-start'>
                                <div className='mb-3 bg-color-secondary d-inline-block ps-1 pe-1 radius-5 fs-2 '>
                                    <span className='text-light'>IBRAMI</span>
                                </div>
                                <h2 className='mb-3 fs-3 text-white'>Sobre nós</h2>

                                <div className='mb-3 small-border bg-white m-auto m-md-0'/>
                            </div>
                        </Flip>
                    </div>

                    <Fade left cascade>
                        <div className='d-flex justify-content-center col-12 col-sm-7 col-lg-8 p-5 p-sm-0'>
                            <div className='text-justify fs-4 text-white'>
                                O Instituto Brasileiro da Melhor Idade (IBRAMI), foi especialmente criado para que as
                                pessoas da melhor idade sejam protagonistas desta fase de sua vida, através da
                                realização de ações que contribuam para sua participação efetiva na sociedade, valorize
                                seu bem-estar, a qualidade de vida e um envelhecimento saudável, ativo e digno.
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>

            <section className={'bg-color-secondary section-padding'}>
                <div className={'container'}>
                    <h2 className='d-flex justify-content-center mb-3 text-white fw-bold fs-1'>
                        Revise a sua aposentadoria
                    </h2>
                    <div className={'d-flex justify-content-center'}>
                        <div className='mb-3 small-border bg-color-primary'/>
                    </div>

                    <div className="row justify-content-center">
                        <div style={{lineHeight: 2}} className=" fs-4 text-white">
                            <div className={'row mb-3'}>
                                <div className={'col-12 col-md-12 col-lg-6 hide-border'}>
                                    <h2 className={'fs-1'}>O que é?</h2>
                                    <div className='mb-3 small-border bg-white'/>
                                    <p className={'ps-4 pe-5'}>
                                        A Revisão da Vida Toda é um processo relevante para aqueles que contribuíram
                                        para a Previdência Social ao longo de suas vidas. Consiste em considerar todos
                                        os salários de contribuição, mesmo os anteriores a julho de 1994, no cálculo da
                                        aposentadoria. Essa revisão pode resultar em benefícios financeiros
                                        significativos, incluindo um aumento considerável no valor mensal do benefício
                                        previdenciário e o recebimento de valores retroativos.
                                    </p>
                                </div>
                                <div className={'col-12 col-md-12 col-lg-6'}>
                                    <h2 className={'fs-1'}>Como saber se tenho direito?</h2>
                                    <div className='mb-3 small-border bg-white'/>
                                    <p className={'px-4'}>
                                        O IBRAMI oferece ajuda nesse processo, analisando os documentos do segurado e
                                        realizando os cálculos necessários para apresentar o resultado. É uma
                                        oportunidade de obter uma aposentadoria mais justa e vantajosa. Entre em contato
                                        com o IBRAMI hoje mesmo para descobrir se você tem direito à Revisão da Vida
                                        Toda e aproveitar esses benefícios.
                                    </p>
                                </div>


                            </div>
                        </div>
                        <div className="row g-2 justify-content-center">
                            <div className={'col-12 col-lg-4 contact-us-button'}>
                                <button onClick={() => window.open(
                                    'https://wa.me/+5567992606586',
                                    '_blank',
                                )}
                                        className={'d-flex align-items-center justify-content-center gap-2 p-4 rounded-3 fs-3 w-100 '}>
                                    <FaWhatsapp size={28}/>

                                    <span>
                                        Entrar em contato
                                    </span>
                                </button>
                            </div>
                            <div className="col-12 col-lg-4 request-now-button">
                                <Link to={'/revisao'} className={'text-decoration-none'}>
                                    <button
                                        className={'btn  w-100 d-flex align-items-center justify-content-center gap-2 p-4 rounded-3 fs-3'}>
                                        <BsBoxArrowUpRight size={28}/>

                                        <span>
                                            Solicitar agora
                                        </span>
                                    </button>
                                </Link>
                            </div>

                            <div className={'col-12 col-lg-4 contact-us-button'}>
                                <Link to={'/indique'} className={'text-decoration-none'}>
                                    <button
                                        className={'d-flex align-items-center justify-content-center gap-2 p-4 rounded-3 fs-3 w-100 '}>
                                        <FaHandsHelping size={28}/>
                                        <span>
                                            Indicar alguém
                                        </span>
                                    </button>
                                </Link>

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <section className='section-padding pt-0'>
                <div className='container'>

                    <div className='d-flex flex-wrap justify-content-evenly'>
                        <Fade left>
                            <div className='col-12 col-md-6 col-lg-4 p-3'>
                                <div className='card-three p-4'>
                                    <div className='bg-icon p-3 mb-3'>
                                        <FaRocket size={32}/>
                                    </div>
                                    <h2 className='mb-3 title'>Missão</h2>
                                    <span className='mb-3 sub-title'>
                                        Contribuir para a melhoria da qualidade de vida, envelhecimento saudável e
                                        bem-estar da população idosa, através da promoção de serviços e ferramentas
                                        pensados e criados exclusivamente para nossos associados.
                                    </span>
                                </div>
                            </div>
                        </Fade>

                        <Fade right>
                            <div className='col-12 col-md-6 col-lg-4 p-3'>
                                <div style={{height: 'initial'}} className='card-three p-4'>
                                    <div className='bg-icon p-3 mb-3'>
                                        <BsFillEyeFill size={32}/>
                                    </div>
                                    <h2 className='mb-3 title'>Visão</h2>
                                    <span className='fs-5 mb-3 sub-title'>
                      Ser a referência como o instituto de serviços mais completo e dedicado à população da melhor idade em nosso país.
                    </span>
                                </div>
                            </div>
                        </Fade>

                        <Fade top>
                            <div className='col-12 col-md-6 col-lg-4 p-3'>
                                <div className='card-three p-4'>
                                    <div className='bg-icon p-3 mb-3'>
                                        <BsAwardFill size={32}/>
                                    </div>
                                    <h2 className='mb-3 title'>Valores</h2>
                                    <span className='fs-5 mb-3 sub-title'>
                      <ul>
                        <li className='pb-3'>Paixão e respeito pela população da melhor idade;</li>
                        <li className='pb-3'>Compromisso na promoção de uma vida melhor a todos os idosos;</li>
                        <li>Servir sempre com dignidade e senso de humanidade, sem distinção.</li>
                      </ul>
                    </span>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>

            <div className=''>
                <div className='d-flex flex-wrap' style={{background: '#F2F6FE'}}>
                    <Fade>
                        <div className='d-none d-sm-block col-6'>
                            <div className='bg-image-8'/>
                        </div>
                    </Fade>
                    <Fade top cascade>
                        <div className='col-12 col-sm-6 p-5'>
                            <div className='mb-3 bg-color-secondary d-inline-block ps-1 pe-1 radius-5 fs-2 '>
                                <span className='text-light'>IBRAMI</span>
                            </div>
                            <h2 className='mb-3 fs-3'>Como trabalhamos</h2>
                            <div className='mb-3 small-border bg-color-secondary'/>
                            <p style={{fontSize: 16, color: '#606060', lineHeight: 2}} className='text-justify fs-4'>
                                Oferecemos a você uma plataforma acessível e completa que promove a inclusão e interação
                                social através de atividades e ferramentas que trabalham o resgate da autoestima e da
                                capacidade funcional, possibilitando manter o idoso ativo fisicamente, social e
                                psicologicamente.
                            </p>
                        </div>
                    </Fade>

                    <Fade bottom cascade>
                        <div className='col-12 bg-color-secondary section-padding'>
                            <div className='container d-flex align-items-center h-100'>
                                <h4 className='text-white fs-2'>
                                    Acolhemos com amor e sensibilidade nossos associados, para que vocês, que muito já
                                    fizeram por todos, agora possam desfrutar da sua melhor idade.
                                </h4>
                            </div>
                        </div>
                    </Fade>

                </div>
            </div>

            <div id='themes' className="container section-padding">
                <h2 className='text-center mb-3 fs-3'>
                    Ferramentas e Conteúdos
                </h2>
                <div className='mb-3 small-border bg-color-secondary m-auto'/>
                <div className='w-100 col-md-12 d-flex justify-content-center align-items-center flex-wrap'
                     style={{height: '500px'}}>
                    <div
                        className="text color-secondary col-md-6 d-none flex-column d-flex align-items-baseline d-lg-flex">
                        <span className='color-primary'>
                            No <span className='color-secondary'>IBRAMI</span> você encontra:
                        </span>

                        <span className='color-secondary'>
                            rede social, cursos,
                        </span>

                        <span className='color-secondary'>
                            novas amizades e
                        </span>

                        <span className='color-primary'>
                            muito mais!
                        </span>
                    </div>

                    <Carousel indicators={false} interval={5000} variant="dark" controls={true}
                              className='col-md-6 col-xxl-5'>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 d-sm-none"
                                src={Images.news}
                                alt="Revenues"
                            />

                            <img
                                className="d-none w-100 d-sm-block"
                                src={Images.phone1}
                                alt="Revenues"
                            />
                        </Carousel.Item>
                        <Carousel.Item>

                            <img
                                className="d-block w-100 d-sm-none "
                                src={Images.chat}
                                alt="Radio, courses, news"
                            />

                            <img
                                className="d-none w-100 d-sm-block"
                                src={Images.phone2}
                                alt="Radio, courses, news"
                            />
                        </Carousel.Item>
                        <Carousel.Item>

                            <img
                                className="d-block w-100 d-sm-none "
                                src={Images.socialmediaphone}
                                alt="Social media, find peoples, groups"
                            />

                            <img
                                className="d-none w-100 d-sm-block"
                                src={Images.phone3}
                                alt="Social media, find peoples, groups"
                            />
                        </Carousel.Item>
                        <Carousel.Item>

                            <img
                                className="d-block w-100 d-sm-none "
                                src={Images.findpeoples}
                                alt="Medicine alarm, courses"
                            />
                            <img
                                className="d-none w-100 d-sm-block"
                                src={Images.phone4}
                                alt="Medicine alarm, courses"
                            />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>

            <div className='bg-color-secondary'>
                <div className='container section-padding  '>

                    <div className='d-flex flex-wrap align-items-center justify-content-evenly gap-4'>
                        <div className='col-12 col-md-6'>
                            <h2 className='text-center mb-3 fs-1 text-uppercase fw-bolder text-light'>
                                Baixe agora!
                                <div className='small-border mt-2 bg-color-primary m-auto'/>
                            </h2>

                            <div className='d-flex flex-wrap justify-content-center'>
                                <Col xs={12} md={6} className='p-1'>
                                    <Button variant='dark' className='w-100'>
                                        <a href="https://play.google.com/store/apps/details?id=org.ibrami"
                                           target="_blank"
                                           rel='noreferrer'
                                           className='d-flex text-decoration-none link-light align-items-center justify-content-center gap-2'>
                                            <img height={20} src={Images.gplay_icon} alt='icon play store'/>
                                            <span className='text-start'>
                          <span className='fw-lighter fs-8'>Disponível no</span> <strong
                                                className='ms-1 d-inline d-md-block'> Google Play </strong>
                        </span>
                                        </a>
                                    </Button>
                                </Col>

                                <Col xs={12} md={6} className='p-1'>
                                    <Button variant={"dark"} className='w-100'>
                                        <a href="https://apps.apple.com/us/app/ibrami/id1643531813"
                                           target="_blank"
                                           rel='noreferrer'
                                           className='d-flex link-light text-decoration-none align-items-center justify-content-center gap-2'>
                                            <FaApple size={24}/>
                                            <span className='text-start'>
                        <span className='fw-lighter fs-8'>Disponível na</span> <strong
                                                className='ms-1 d-inline d-md-block'>App Store </strong>
                      </span>
                                        </a>
                                    </Button>
                                </Col>
                            </div>

                            <div className='fs-1 fw-bold text-light p-4 p-md-0'>
                                <div>
                                    Aponte a câmera do
                                </div>
                                <div>
                                    seu celular para o
                                </div>
                                <div>
                                    QR Code e <span className='d-block d-lg-inline' style={{color: '#55d1b4'}}>baixe o aplicativo!</span>
                                </div>
                                <div className='d-block d-md-none'>
                                    ou <a href="https://ibrami.page.link/acesso" className='link-light'>Clique aqui para
                                    baixar</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-md-4 col-lg-4 col-xl-3'>
                            <img src={Images.qrcode} alt='qrcode' style={{border: '3px solid #42A38D'}}
                                 className='rounded-2 w-100 p-2'/>
                        </div>
                    </div>
                </div>
            </div>

            <section id='monthly' className=''>
                <div className=''>
                    <div className='d-flex flex-wrap align-items-center' style={{background: '#F2F6FE'}}>
                        <Fade>
                            <div className='d-none d-sm-block col-6'>
                                <div className='bg-image-9' style={{minHeight:580}}/>
                            </div>
                        </Fade>
                        <Fade top cascade>
                            <div className='col-12 col-sm-6 px-4 py-5 py-md-0' >
                                <div className={'d-flex justify-content-center align-items-center'} >
                                    <button className={'btn segunda-via  p-0 col-12 col-md-9 col-lg-7'} >
                                        <a className={'align-items-center'}
                                           id={"boleto"}
                                           href="https://wa.me/+556792606586?text=Olá! Gostaria de solicitar a segunda via da minha mensalidade associativa." target={"_blank"}>

                                                {/*<BsReceipt/>*/}
                                            <img src={Images.boleto} style={{width:120}} alt=""/>

                                            <p className={'mb-0 px-2'}>Solicitar segunda via da mensalidade associativa</p>
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>


            </section>

        </>
    )
}

export default HomePage
