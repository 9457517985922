import React, {useEffect, useState, useContext, useRef} from "react";
import stepImage from "../../../assets/images/Add files-amico.svg";
import ImageButtonComponent from "../../../components/ImageButtonComponent";
import {DropzoneAttachmentsComponent} from "../../../components/DropzoneAttachmentsComponent";
import {FaCheck, FaPlay} from "react-icons/fa";
import axios from "axios";

const Doc1Step = (props) => {
    const [step, setStep] = useState('INITIAL_STEP');
    const [document, setDocument] = useState(null)
    const [cnis, setCnis] = useState(false);
    const [loading, setLoading] = useState(false);

    const sendBase64 = async (e) => {
        props.saveData('document_cnis_base64', e)
        setDocument({document_cnis_base64: e})
    }
    const handleRemove = () => {
        setDocument(null)
    }

    const handleNext = async () => {
        setLoading(true)
        if(!cnis) await props.saveDocApi()
        props.setStep()
        setLoading(false)
    }

    useEffect(() => {
        setCnis(props.docsSent.cnis)
    }, []);

    return (

        <div className="container text-center">

            <div className={`${step === 'INITIAL_STEP' ? 'd-block' : 'd-none'}`}>
                <ImageButtonComponent title={'Insira o seu CNIS'}
                                      description={'CNIS é o extrato das suas contribuições previdenciárias, a partir dele faremos os cálculos do seu reajuste'}
                                      image={stepImage}
                />
                <button type="button"
                        onClick={() => setStep('HOW_TO_GET')}
                        style={{marginTop: -10}}
                        className={`mb-4 bg-ibrami-secondary align-items-center bg-opacity-90-hover btn-reset px-4 py-2 rounded-pill text-white`}>
                    <FaPlay style={{marginRight: 4, marginTop: -3}}/>
                    <span>Como conseguir meu CNIS</span>
                </button>

                <div className={`px-4 row justify-content-center`}>
                    <div className={'col-12 col-md-4 col-lg-5'}>

                        <div className={`dropzone ${(cnis) ? 'd-block' : 'd-none'}`} style={{backgroundColor:'#c8e6c9', borderColor:'#43a047'}} onClick={() => setCnis(false)}>
                            <div className={'d-flex align-items-center justify-content-center '}>
                                <FaCheck color={'#43a047'} className={'p-2 bg-white rounded-circle'} size={72}/>
                            </div>

                            <div>
                                <h2 style={{color:'#2e7d32'}}>
                                   O documento já foi enviado!
                                </h2>
                                <h5><strong>Clique aqui se deseja reenviar!</strong></h5>
                            </div>
                        </div>

                        <div className={`${(!cnis ? 'd-block' : 'd-none')}`}>

                            <DropzoneAttachmentsComponent customText={'Clique aqui para enviar o CNIS'}
                                                          attachments={(e) => sendBase64(e)}
                                                          handleRemove={() => handleRemove()}
                                                          onlyBase64={true}
                                                          styleSelectedFile={{minHeight:'auto', cursor:'pointer'}}
                                                          maxAttachments={1}
                            />
                        </div>
                    </div>
                </div>

                <div className={'row   justify-content-center my-3'}>
                    <button type="button" disabled={(cnis ? !cnis : !document) || loading}
                            onClick={() => handleNext()}
                            className={`col-11 col-md-7 col-lg-5 ${((cnis ? !cnis : !document) || loading) ? 'opacity-50' : ''} btn-reset btn-ibrami-primary`}>
                        {loading ? 'Salvando...' : 'Continuar'}
                    </button>
                </div>
            </div>


            <div className={`my-4 ${step === 'HOW_TO_GET' ? 'd-block' : 'd-none'}`}>
                <h3 className={`d-flex justify-content-center text-ibrami-secondary mb-3`} >
                    <span className={`px-2 py-1 rounded-2`} style={{background:'#6b53931c'}}>Como conseguir meu CNIS</span>
                </h3>
                <div className={`row`}>
                    <div className="col-12 px-3" style={{minHeight:650}}>
                        <iframe width="100%" height="100%"
                                className={` rounded-4`}
                                src="https://www.youtube.com/embed/awITf3pacJE"
                                title="Revisão da Vida Toda - Saiba como extrair o seu CNIS e Carta de Concessão do INSS"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </div>
                </div>
                <div>
                    <button className={`col-12 btn btn-outline-dark rounded-pill mt-4`} onClick={() => setStep('INITIAL_STEP')}>Voltar</button>
                </div>
            </div>
        </div>

    );
};

export default Doc1Step;
