import NumberFormat from "react-number-format";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {ErrorMessageComponent} from "../../../components/ErrorMessageComponent";
import {validateDate} from "../../../Helpers/Helpers";
import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import axios from "axios";
import {toast} from "react-toastify";

export const FormComponent = (props) => {
	const registerSchema = yup.object().shape({
		name: yup.string().required('Informe seu nome'),
		cpfcnpj: yup.string().required('É necessário informar seu CPF').test('cpf', 'Insira um CPF válido', (value) => {
			return value && value.length === 11
		}),
		natural_birthday: yup.string(),
		// natural_gender: yup.string().required('Informe seu gênero'),
		contact_mail: yup.string().email('Insira um e-mail válido').required('O email é obrigatório'),
		contact_mobile_phone: yup.string().required('Informe seu número de celular').min(11, 'Insira um número válido'),
		address_cep: yup.string().required('Informe seu CEP').min(8, 'Insira um CEP válido'),
		address_city_id: yup.string().required('Informe sua cidade'),
		state_id: yup.string().required('Informe seu estado'),
		address_number: yup.string().required('Informe o número da residência'),
		natural_civil_status_id: yup.string().required('Informe seu estado civil'),
	});


	const {
		register,
		getValues,
		handleSubmit,
		control,
		reset,
		setValue,
		watch,
		formState: {errors,}
	} = useForm({resolver: yupResolver(registerSchema)});

	const [cityList, setCityList] = useState({});

	const callCepRef = useRef(false)

	const handleSave = async (data) => {
		if (props.person?.address_city) {
			data.address_city.state_id = data.state_id
		}

		props.handleSaveForm(data);
		props.nextStep();
	}

	const onError = (e) => {
	}

	const checkCEP = async (cep) => {
		try {
			let res = await axios.get(`search/cep/${cep}`)
			const data = res.data.object
			let updateAddress = {
				...getValues(),
				state_id: `${data?.city?.state_id}`,
				address_city_id: `${data?.city_id}`,
				address_street: data?.street,
				address_district: data?.district,
				address_state_uf: data?.state_uf
			}
			await getAddress(data?.state_uf)
			reset(updateAddress)
		} catch (e) {
			toast.error(e.response.data.message || 'Ocorreu um erro')
		}
	}

	const getAddress = async (uf) => {
		try {
			let save = cityList
			let cities = await axios.get(`search/city/${uf}`)
			save.cities = cities.data.object.cityList
		} catch (e) {
			console.log(e)
		}
	}

	const autoFillForm = async (e) => {
		let aux = {...e}
		let getStates = await axios.get(`search/state/list`);
		if (props.person.state_id) {
			await getCities(aux.state_id, getStates.data.object)
		} else {
			setCityList({...cityList,states:getStates.data.object})
		}
		reset(aux);
	}

	const getCities = async (uf,states) => {
		let cities = await axios.get(`search/city/${uf}`)
		if (states) {
			setCityList({...cityList, cities:cities.data.object.cityList, states:states})
		} else {
			setCityList({...cityList, cities:cities.data.object.cityList})
		}
	}
	useEffect(() => {
		if (props?.person) {
			if (props.person?.address_city) {
				props.person.state_id = `${props.person?.address_city.state_id}`
			}

			if (!props.person.state_id) {
				callCepRef.current = true
			}
			autoFillForm(props?.person)
		}

	}, [props.person]);

	return (
		<form id={'Editar dados'} name={'Edição de perfil'} autoComplete={'off'}
			  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} onSubmit={handleSubmit(handleSave, onError)}>

			<div className={'row my-3'}>
				<div className="col-12 col-lg-6 mb-2">
					<label htmlFor={'cpfcnpj'} className='form-label'>CPF</label>
					<Controller name='cpfcnpj'
								control={control}
								render={({field: {onChange, value}}) => (
									<NumberFormat
										className={`form-control custom-focus form-control-lg ${errors?.cpfcnpj?.message ? ' is-invalid' : 'form-control-solid'}`}
										id={"cpfcnpj"}
										disabled={true}
										name={'cpfcnpj'}
										value={value}
										onValueChange={(e) => {
											onChange(e.value)
										}}
										placeholder='000.000.000-00'
										format={'###.###.###-##'}
										mask="_"
									/>
								)}
					/>
					{errors?.cpfcnpj?.message &&
						<div className='mt-2'>
							<ErrorMessageComponent message={errors?.cpfcnpj?.message}/>
						</div>
					}
				</div>

				<div className="col-12 col-lg-6 mb-2">
					<label htmlFor={'edit-name'} className='form-label'>Nome</label>
					<input
						className={`form-control custom-focus form-control-lg ${errors?.name?.message ? ' is-invalid' : 'form-control-solid'}`}
						type="text"
						disabled={true}
						id={'edit-name'}
						name="name"
						placeholder="Nome"
						autoComplete="off"
						{...register('name')}
					/>
					{errors?.name?.message &&
						<div className='mt-2'>
							<ErrorMessageComponent message={errors?.name?.message}/>
						</div>
					}
				</div>

				<div className="col-12 col-lg-6 mb-2">
					<label htmlFor={"natural_birthday"} className='form-label'>Data de nascimento</label>
					<Controller name='natural_birthday'
								control={control}
								render={({field: {onChange, value}}) => {
									return (
										<NumberFormat
											className={`form-control custom-focus form-control-lg ${errors?.natural_birthday?.message ? ' is-invalid' : 'form-control-solid'}`}
											id={"natural_birthday"}
											name={'natural_birthday'}
											value={moment(value).format('DD/MM/YYYY')}
											disabled={true}
											placeholder='00/00/0000'
											format={'##/##/####'}
											mask="_"
										/>
									)
								}}
					/>
					{errors?.natural_birthday?.message &&
						<div className='mt-2'>
							<ErrorMessageComponent message={errors?.natural_birthday?.message}/>
						</div>
					}
				</div>

				<div className={`mb-2`}>
					<label htmlFor={'natural_civil_status_id'} className='form-label'>Estado Civil</label>
					<select {...register('natural_civil_status_id')} className={`form-select form-select-lg custom-focus ${errors?.natural_civil_status_id?.message ? ' is-invalid' : 'form-select'}`} id={'natural_civil_status_id'}>
							<option value="">Selecione uma opção</option>
							<option value="2">Solteiro(a)</option>
							<option value="3">Viúvo(a)</option>
							<option value="4">Separado(a) Judicialmente</option>
							<option value="5">Divorciado(a)</option>
							<option value="6">União estável</option>
							<option value="1">Casado(a)</option>
					</select>
					{errors?.natural_civil_status_id?.message &&
						<div className='mt-2'>
							<ErrorMessageComponent message={errors?.natural_civil_status_id?.message}/>
						</div>
					}
				</div>

				<div className="col-12 col-lg-6 mb-2">
					<label htmlFor="contact_mobile_phone" className='form-label'>Celular</label>
					<Controller name='contact_mobile_phone'
								control={control}
								render={({field: {onChange, value}}) => (
									<NumberFormat
										className={`form-control custom-focus form-control-lg ${errors?.contact_mobile_phone?.message ? ' is-invalid' : 'form-control-solid'}`}
										id={'contact_mobile_phone'}
										name={'Celular'}
										value={value}
										onValueChange={(e) => onChange(e.value)}
										placeholder='Celular'
										format={'(##) #####-####'}
										mask="_"
									/>
								)}
					/>

					{errors?.contact_mobile_phone?.message &&
						<div className='mt-2'>
							<ErrorMessageComponent message={errors?.contact_mobile_phone?.message}/>
						</div>
					}
				</div>
			</div>

			<div className='mb-3 border-top border-gray-300'>
				<div className='my-2'>
					<h3>Endereço</h3>
				</div>
				<div className='col-12 col-md-5'>
					<label htmlFor={'address_cep'}>CEP</label>
					<Controller name='address_cep'
								control={control}
								render={({field: {onChange, value}}) => (
									<NumberFormat
										className={`form-control custom-focus form-control-lg ${errors?.address_cep?.message ? ' is-invalid' : 'form-control-solid'}`}
										id={"address_cep"}
										name={'address_cep'}
										value={value}
										onValueChange={(e) => {
											onChange(e.value)
											if (e.value.length === 8) {
												if (callCepRef.current) checkCEP(e.value)
												if (callCepRef.current === false) {
													callCepRef.current = true
												}
											}
										}}
										placeholder={`xx.xxx-xxx`}
										format={'##.###-###'}
										mask="_"
									/>
								)}/>
					{errors?.address_cep?.message &&
						<div className='mt-2'>
							<ErrorMessageComponent message={errors?.address_cep?.message}/>
						</div>
					}
				</div>
				<div className='mb-3 row'>
					<div className='col mb-3 mb-md-0'>
						<label htmlFor={'address_street'}>Rua</label>
						<input
							   className={`form-control custom-focus form-control-lg ${errors?.address_street?.message ? ' is-invalid' : 'form-control-solid'}`}
							   type="text"
							   id={'address_street'}
							   name={'Rua'}
							   placeholder={'Rua'}
							   {...register('address_street')}
						/>
						{errors?.address_street?.message &&
							<div className='mt-2'>
								<ErrorMessageComponent message={errors?.address_street?.message}/>
							</div>
						}
					</div>
					<div className='col-12 col-md-3'>
						<label htmlFor={'address_number'}>Número</label>
						<input
							className={`form-control custom-focus form-control-lg ${errors?.address_number?.message ? ' is-invalid' : 'form-control-solid'}`}
							type="number"
							id={'address_number'}
							name={'Número'}
							placeholder={'Número'}
							{...register('address_number')}
						/>
						{errors?.address_number?.message &&
							<div className='mt-2'>
								<ErrorMessageComponent message={errors?.address_number?.message}/>
							</div>
						}
					</div>

				</div>

				<div className='mb-3'>
					<label htmlFor={'address_district'}>Bairro</label>
					<input
						   className={`form-control custom-focus form-control-lg ${errors?.address_district?.message ? ' is-invalid' : 'form-control-solid'}`}
						   type="text"
						   id={'address_district'}
						   name={'Bairro'}
						   placeholder={'Bairro'}
						   {...register('address_district')}
					/>
					{errors?.address_district?.message &&
						<div className='mt-2'>
							<ErrorMessageComponent message={errors?.address_district?.message}/>
						</div>
					}
				</div>

				<div className='mb-3 row'>
					<div className={`col-12 col-md-6`}>
						<label htmlFor={'address_city_id'} className='form-label'>Cidade</label>
						<select {...register('address_city_id')}
								defaultValue={watch().address_city_id || ""}
								className={`form-select form-select-lg custom-focus ${errors?.address_city_id?.message ? ' is-invalid' : 'form-select'}`}
								id={'address_city_id'}
						>
							<option value="" disabled>Selecione uma opção</option>
							{cityList?.cities?.map((item, index) =>
								<option value={item.city_id} key={index}>{item.name}</option>
							)}
						</select>
						{errors?.address_city_id?.message &&
							<div className='mt-2'>
								<ErrorMessageComponent message={errors?.address_city_id?.message}/>
							</div>
						}
					</div>

					<div className={`col-12 col-md-6`}>
						<label htmlFor={'state_id'} className='form-label'>Estado</label>
						<select {...register('state_id')}
								className={`form-select form-select-lg custom-focus ${errors?.state_id?.message ? ' is-invalid' : 'form-select'}`}
								onChange={async (e) => {
									await getCities(e.target.value);
									setValue('address_city_id', '')
								}}
								id={'state_id'}
						>
							<option value="">Selecione uma opção</option>
							{cityList.states?.map((item, index) =>
								<option value={item.state_id} key={index}>{item.name}</option>
							)}
						</select>
						{errors?.state_id?.message &&
							<div className='mt-2'>
								<ErrorMessageComponent message={errors?.state_id?.message}/>
							</div>
						}
					</div>

				</div>
				<div className='mb-3'>
					<label htmlFor={'address_complement'}>Complemento</label>
					<input
						className={`form-control custom-focus form-control-lg ${errors?.address_complement?.message ? ' is-invalid' : 'form-control-solid'}`}
						type="text"
						id={'address_complement'}
						name={'Complemento'}
						placeholder={'Complemento'}
						{...register('address_complement')}
					/>
					{errors?.address_complement?.message &&
						<div className='mt-2'>
							<ErrorMessageComponent message={errors?.address_complement?.message}/>
						</div>
					}
				</div>
			</div>
			<div className="row justify-content-center">
				<button className={'col-12 col-md-6 col-lg-4 btn-reset p-2 fs-1 btn-ibrami-primary'} type="submit">Próximo</button>
			</div>
		</form>
	)
}
