import React, {useEffect, useState, useContext, useRef} from "react";
import stepImage from "../../../assets/images/Calling-amico.svg";
import thank from "../../../assets/images/Mail sent-rafiki.svg";
import ImageButtonComponent from "../../../components/ImageButtonComponent";
import NumberFormat from "react-number-format";
import axios from "axios";
import {useQueryString, validateMobilePhone} from "../../../Helpers/Helpers";
import {toast} from "react-toastify";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {ErrorMessageComponent} from "../../../components/ErrorMessageComponent";

const PhoneStep = (props) => {

    const schema = yup.object().shape({
        retiree_name: yup.string().required('O nome é obrigatório').min(7, 'Informe seu nome e sobrenome'),
        mobile_number: yup.string().required('Informe seu número de telefone').test('is-valid', d => `Número inválido`, value => validateMobilePhone(value),).min(11, 'Insira um número válido'),
        mobile_confirm_number: yup.string().oneOf([yup.ref('mobile_number'), null], 'Os números não coincidem').required('Confirme o número informado acima')
    });

    const {register, setValue, handleSubmit, control, getValues, trigger, formState: { errors },} = useForm({
        resolver: yupResolver(schema),
    });

    const {queryToObject} = useQueryString()
    const saveData = async (e) => {
        let qs = queryToObject(window.location?.search)
        delete e.mobile_confirm_number
        try {
            await axios.post(`inss-calc/`, {firebase_token: "site", ...e, ...qs});
            props.saveData('mobile_number', e.mobile_number)

            let res = await axios.get(`inss-calc/${e.mobile_number}`);
            props.checkDocSent(res.data.object);
            props.setStep();
        } catch (err) {
            toast.error( 'Parece que ocorreu um erro, tente novamente mais tarde!', {position:'top-right'});
        }
    }

    const onError = (e) => {
        console.log(e)
    }

    return (

        <div className="container">
            <div className={`row`} style={{marginTop:"3rem"}}>
                <div className={`col-12 col-md-5`}>
                    <ImageButtonComponent title={<span style={{fontSize:'2rem'}}>Insira seu telefone</span>}
                                          description={<span style={{fontSize:'1.25rem'}}>Fique atento, informaremos os detalhes do reajuste da sua aposentadoria neste número</span>}
                                          image={stepImage}

                    />
                </div>

                <div className={`col-12 col-md-7`}>
                    <form onSubmit={handleSubmit(saveData, onError)} className={`row justify-content-center`}>
                        <div className={`col-12 col-md-10 col-lg-8`}>
                            <label htmlFor="name">Qual seu nome?</label>
                            <input type="text" id={'name'} {...register('retiree_name')} style={{textAlign:'center'}}
                                   placeholder={'Digite aqui seu nome'}
                                   autoFocus
                                   className={`form-control form-control-lg custom-focus ${errors?.retiree_name?.message ? ' is-invalid' : 'form-control'}`}
                            />

                            {errors?.retiree_name?.message &&
                                <div className='mt-2'>
                                    <ErrorMessageComponent message={errors?.retiree_name?.message}/>
                                </div>
                            }
                        </div>

                        <div style={{marginTop:"2rem"}} className={'col-12 col-md-10 col-lg-8 mb-3'}>
                            <label htmlFor="mobile_number">Celular:</label>
                            <Controller name='mobile_number'
                                        control={control}
                                        render={({field: {onChange, value}}) => (
                                            <NumberFormat
                                                type={"tel"}
                                                style={{textAlign: 'center', minWidth:300}}
                                                placeholder={'Digite o número do seu Celular'}
                                                id={'mobile_number'}
                                                className={`form-control form-control-lg custom-focus ${errors?.mobile_number?.message ? ' is-invalid' : 'form-control'}`}
                                                value={value}
                                                onValueChange={(e) => onChange(e.value)}
                                                format={'(##) #####-####'} mask="_"
                                            />
                                        )}
                            />
                            {errors?.mobile_number?.message &&
                                <div className='mt-2'>
                                    <ErrorMessageComponent message={errors?.mobile_number?.message}/>
                                </div>
                            }
                        </div>
                        <div className={'col-12 col-md-10 col-lg-8 mb-3'}>
                            <label htmlFor="mobile_confirm_number">Confirme seu número:</label>
                            <Controller name='mobile_confirm_number'
                                        control={control}
                                        render={({field: {onChange, value}}) => (
                                            <NumberFormat
                                                type={"tel"}
                                                style={{textAlign: 'center', minWidth:300}}
                                                placeholder={'Confirme seu número'}
                                                id={'mobile_confirm_number'}
                                                className={`form-control form-control-lg custom-focus ${errors?.mobile_confirm_number?.message ? ' is-invalid' : 'form-control'}`}
                                                value={value}
                                                onValueChange={(e) => onChange(e.value)}
                                                format={'(##) #####-####'} mask="_"
                                            />
                                        )}
                            />
                            {errors?.mobile_confirm_number?.message ?
                                <div className='mt-2'>
                                    <ErrorMessageComponent message={errors?.mobile_confirm_number?.message}/>
                                </div>:

                                <div className={'mt-2 text-center  py-1 px-2 rounded-2'} style={{background:'#1887541f'}}>
                                    <small className={'text-success'} ><strong>*</strong> Entraremos em contato com você nesse número, <br/> <strong className={'text-uppercase'}>confirme se ele está correto!</strong></small>
                                </div>
                            }
                        </div>

                        <div className="d-flex justify-content-center w-100">
                            <button type="submit"
                                    className={`col-12 col-md-10 col-lg-8 btn-reset btn-ibrami-primary rounded-pill`}>
                                Próximo
                            </button>
                        </div>
                    </form>
                </div>



            </div>

        </div>

    );
};

export default PhoneStep;
