import React, {useEffect, useState, useContext, useRef} from "react";
import camera from "../../../assets/images/Camera-bro.svg";
import ImageButtonComponent from "../../../components/ImageButtonComponent";
import Webcam from "react-webcam";
import law from "../../../assets/images/logo-advocacia.jpeg"
import {getOnlyBase64, scrollTo} from "../../../Helpers/Helpers";
import WebCamComponent from "../../../components/WebCamComponent";
import {FaArrowLeft} from "react-icons/fa";


const AcceptDocWithSelfie = (props) => {
    const [step, setStep] = useState('PROCURATION');
    const [previewImage,setPreviewImage] = useState(null)

    const handleTakePicture = (e) => {
        setPreviewImage(e)
        props.handleSaveForm({document_selfie: getOnlyBase64(e)})
    }

    const handleBack = () => {
        console.log(step)
      if (step === 'PROCURATION') {
          console.log('aqui')
          props.setStep(3)
      } else if (step === 'TERMS') {
          setStep('PROCURATION')
      } else if (step === 'DIALOG_SELFIE') {
          setStep('TERMS')
      } else if (step === 'SELFIE') {
          setStep('PROCURATION')
      }
    }

    return (
        <div className={'p-4'} style={{minHeight:'40vh'}}>
            <button type="button" onClick={() => handleBack()}
                    className={`btn d-flex align-items-center gap-1 rounded-pill btn-outline-dark mb-3`}>
                <FaArrowLeft size={12}/> <span style={{fontSize:'0.75rem'}}>Voltar</span>
            </button>

            {(step === 'PROCURATION') &&
                <div>
                    <div className={`d-flex justify-content-center mb-3 `}>
                        <img src={law} alt="" style={{maxHeight:125}} className={``}/>
                    </div>
                    <h1 className={'text-center text-uppercase my-3'}>
                        Procuração
                    </h1>
                    <div className={`pb-4 pt-2`} dangerouslySetInnerHTML={{ __html: props?.procuration }} />
                    <div className={`col-12 col-md-6 col-lg-4 order-0 order-md-1 `}>
                        <button onClick={() => {
                            scrollTo('full-steps')
                            setStep('TERMS')
                        }} className={`btn-reset btn-ibrami-primary p-2 fs-1 w-100`}>
                            Ciente e de Acordo
                        </button>
                    </div>
                </div>
            }
            {(step === 'DIALOG_SELFIE') &&
                <div className={`text-center`}>
                    <div className={`d-flex justify-content-center `}>
                        <h3 className={`text-ibrami-primary fw-bolder px-2 py-1 rounded-2 mb-0`}  style={{background:'#42a38d2b'}}>
                            Permissão da câmera necessária!
                        </h3>
                    </div>

                    <img src={camera} style={{maxWidth:350}} className={`shadow-sm rounded-2 my-4`} alt=""/>
                    <div>
                        <h5 className={`text-ibrami-secondary`}>
                            A seguir precisamos que você clique em <span style={{background:'#6b539430'}} className={`px-2  rounded-2`}>"permitir"</span> para tirar a foto e assinar o documento!
                        </h5>
                    </div>
                    <button onClick={() => setStep('SELFIE')} className={`btn-reset btn-ibrami-primary p-2 fs-1 w-100 mt-3`}>
                        Assinar com a Selfie
                    </button>
                </div>
            }
            {step === 'TERMS' ?
                <div>
                    <div className={`d-flex justify-content-center mb-3 `}>
                        <img src={law} alt="" style={{maxHeight:125}} className={``}/>
                    </div>
                    <div className={`pb-4 pt-2`} dangerouslySetInnerHTML={{ __html: props?.authorization }} />

                    <div className={`row justify-content-center mt-4`}>


                        <div className={`col-12 col-md-6 col-lg-4 order-0 order-md-1 `}>
                            <button onClick={() => {
                                scrollTo('full-steps')
                                setStep('DIALOG_SELFIE')
                            }} className={`btn-reset btn-ibrami-primary p-2 fs-1 w-100`}>
                                Assinar com a Selfie
                            </button>
                        </div>
                    </div>

                </div>
                :
               ((step === 'SELFIE') &&
                    <div>
                        {previewImage ?
                            <>
                                <div>
                                    <h3 className={`px-2 py-1 text-ibrami-secondary rounded-2`} style={{background:'#6b539317'}}>Confira se a foto ficou nítida</h3>
                                </div>
                                <div className={`text-center pb-3`}>
                                    <img src={previewImage}
                                         alt={'selfie'}
                                         className={'rounded-2'}
                                         style={{maxHeight: 300, objectPosition: "50% 50%", objectFit:'cover'}}
                                    />
                                </div>
                                <div className={`row justify-content-between`}>


                                    <div className={`col-6 px-1 `}>
                                        <button className={'btn btn-outline-dark rounded-pill p-1 fs-1 w-100'}
                                                onClick={() => setPreviewImage(null)}>Tirar outra</button>
                                    </div>
                                    <div className={`col-6 px-1`}>
                                        <button className={' btn-reset btn-ibrami-primary p-1 fs-1 w-100'}
                                                onClick={() => props.nextStep()}>
                                            Próximo
                                        </button>
                                    </div>
                                </div>
                            </>

                            :
                            <WebCamComponent handleTakePicture={(e) => handleTakePicture(e)}
                                             selfie={true}
                                             closeCamera={() => setStep('PROCURATION')}
                            />
                        }

                    </div>
               )
            }
        </div>
    );
};

export default AcceptDocWithSelfie;
