import {Footer} from "./Footer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import {HelmetProvider} from "react-helmet-async";
import '../styles.scss';
import '../assets/icofont/icofont.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
export const DefaultLayout = ({children, withoutFooter}) => {

	const helmetContext = {};

	axios.defaults.baseURL = (process.env.NODE_ENV  === 'production') ? 'https://api.ibrami.org/app/' : 'https://dev.ibrami.org/app/'


	return (
		<HelmetProvider context={helmetContext}>
			<div className={`d-flex flex-column`}>
				<ToastContainer />
				<main style={{flex:1, minHeight:'90vh'}}>
					{children}
				</main>
				{withoutFooter ? <></> : <Footer/>}
			</div>
		</HelmetProvider>
	)
}