import React, { useEffect, useState, useContext } from "react";

import {FaCamera, FaCheck, FaClipboardList, FaHandsHelping, FaSignature, FaUser} from "react-icons/fa";
import WelcomeStep from "./Components/WelcomeStep";
import EndStep from "./Components/EndStep";
import MultiStepProgress from "../../components/MultiStepProgress";
import Doc1Step from "./Components/Doc1Step";
import Doc2Step from "./Components/Doc2Step";
import PhoneStep from "./Components/PhoneStep";
import {Container, Navbar, NavbarBrand} from "react-bootstrap";
import {Images} from "../../assets/Images";
import {Link} from "react-router-dom";
import {ProgressBarTracker} from "../../components/ProgressBarTracker";
import {BsFillQuestionCircleFill, BsFillTelephoneFill} from "react-icons/bs";
import axios from "axios";
import {scrollTo, useQueryString} from "../../Helpers/Helpers";
import {toast} from "react-toastify";
import SEO from "../../DefaultLayout/SEO";
import ReactGA from "react-ga4";

const MainStepsPage = (props) => {
    const [step, setStep] = useState(0);
    const {queryToObject} = useQueryString()

    const [loading,setLoading] = useState(false);
    const [allSteps, setAllSteps] = useState([{
        title: 'Vamos começar!',
        icon: <FaUser/>,
        id:1
    },{
        title: 'Contato',
        icon: <BsFillTelephoneFill/>,
        id:2
    },
        {
            headerTitle: 'Qual seu celular?',
            headerSubTitle: 'Entraremos em contato após a análise de documentos!',
            title: 'CNIS',
            icon: <FaClipboardList/>,
            id:3
        },
        {

            title: 'Carta de concessão',
            icon: <FaClipboardList/>,
            id:4,
        },
        {
            headerTitle: 'Fim',
            headerSubTitle: 'Não precisa mais se preocupar!',
            title: 'Fim',
            icon: <FaCheck/>,
            id:5
        }]);

    const [data, setData] = useState({firebase_token: "site"});
    const [documentsSent, setDocumentsSent] = useState(null);

    const saveData = (label, value) => {
        setData({...data,[label]:value});
    }


    const handleChangeStep = (step) => {
        setStep(step)
        document.getElementById(allSteps[step].id).scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        });
        ReactGA.send({ hitType: "pageview", title: `${props.attendant? 'Atendente - Revisão' : 'Revisão'} | ${allSteps[step].title}` });
    }

    const saveDocApi = async () => {
        try {
            await axios.post(`inss-calc/`, data);
        } catch (e) {
            toast.error('Opss! Parece que ocorreu um erro, tente novamente em alguns minutos!')
        }
    }

    const submitData = async () => {
        setLoading(true)
        await axios.post('inss-calc', data).then((response) => {
            scrollTo('a92zm')
            setStep(4)
            ReactGA.send({ hitType: "pageview", title: `${props.attendant? 'Atendente - Revisão' : 'Revisão'} | ${allSteps[4].title}` });

        }, (error) => {
            console.log(error);
        });
        setLoading(false)

    }


    useEffect(() => {
        let qs = queryToObject(window.location?.search)
        setData({...data, ...qs})
        scrollTo('a92t')
    }, []);


    return (
        <>
            <SEO title={`${props.attendant? 'Atendente - Revisão' : 'Revisão'} | ${allSteps[step].title}`}
                 description={props.attendant?'Atendente' : 'Revisão'}
                 name={props.attendant?'Atendente' : 'Revisão'}
                 type='article'
            />
            <Navbar expand="lg" className={'shadow-sm'} style={{backgroundImage: 'white', background: '#fff'}}>
                <Container>
                    <NavbarBrand>
                        <Link to={'/'}>
                            <img alt='logo' src={Images.ibramiLogo} style={{width: 240}}/>
                        </Link>

                    </NavbarBrand>
                </Container>
            </Navbar>
            <div className="container my-5" id={'a92t'}>

                <div className={'text-gray-600'}>
                    <h1 className={'text-ibrami-primary '}>Vamos aumentar sua aposentadoria?</h1>
                    <p className={'text-muted ps-2'}>Complete o formulário e veja se tem direito ao reajuste na sua aposentadoria</p>
                </div>



                <div className="d-flex custom-progress container bg-white p-0 shadow rounded flex-column" id={'a92zm'} >

                    {step !== undefined && <ProgressBarTracker totalStep={5} activeStep={step + 1}/>}
                    {step !== undefined && <MultiStepProgress step={step} steps={allSteps}/>}
                    <div className={''} style={{minHeight:'40vh'}}>
                        {step === 0 &&<WelcomeStep  setStep={(e) => handleChangeStep(1)} />}

                        {step === 1 &&<PhoneStep saveData={(label, value) => saveData(label, value)}
                                                 checkDocSent={(e) => setDocumentsSent(e)}
                                                 setStep={(e) => handleChangeStep(2)}  />}

                        {step === 2 &&<Doc1Step saveData={(label, value) => saveData(label, value)}
                                                docsSent={documentsSent}
                                                saveDocApi={() => saveDocApi()}
                                                setStep={(e) => handleChangeStep(3)}/>}

                        {step === 3 &&<Doc2Step saveData={(label, value) => saveData(label, value)}
                                                docsSent={documentsSent}
                                                setStep={(e) => submitData()}
                                                loading={loading}/>}

                        {step === 4 &&<EndStep setStep={(e) => setStep(e)}/>}
                    </div>

                    <div className="w-100 text-center mt-0 p-4">
                        <div className="border-top my-3 w-100 text-center"/>
                        <div className={'row g-2 g-md-0 justify-content-center'}>
                            <a href={`https://ibrami.org/whatsapp-revisao`}
                               className={'col-12 col-md-6 text-decoration-none d-flex align-items-center justify-content-center gap-1'}>
                                <BsFillQuestionCircleFill color={'gray'} size={14}/>
                                <span style={{fontSize: 13, color: "gray"}}>
                                                        Está com dúvidas? <strong className="text-secondary text-decoration-underline">Clique aqui</strong>
                                                    </span>
                            </a>

                            <Link to={'/indique'}
                                  style={{fontSize:13}}
                                  className={'col-12 col-md-6 d-flex align-items-center justify-content-center gap-1 text-decoration-none'}>
                                <FaHandsHelping/>
                                Tem alguém para indicar? <strong className={'text-decoration-underline'}>Clique aqui</strong>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default MainStepsPage;
