import React from 'react'
import {createBrowserRouter} from "react-router-dom";
import Terms from "../page/Terms/Terms";
import MainStepsPage from "../page/SendDocs/MainStepsPage";
import MainStepsPageFull from "../page/SendDocsFull/MainStepsPage";
import {DefaultLayout} from "../DefaultLayout/DefaultLayout";
import HomePage from "../page/HomePage";
import ReferPage from "../page/Refer/ReferPage";
import AttendantSendDocsPage from "../page/SendDocs/AttendantSendDocsPage";
import RemoveAccountPage from "../page/RemoveAccount/RemoveAccountPage";

export const PathRouters = createBrowserRouter([
        {
            path: "/",
            element: <DefaultLayout children={<HomePage/>}/>,
        },
        {
            path: 'termos',
            element: <DefaultLayout children={<Terms/>}/>,
        },
        {
            path: 'revisao',
            element: <DefaultLayout children={<MainStepsPage/>} withoutFooter={true}/> ,
        },
        {
            path: 'revisao/atendente',
            element: <DefaultLayout children={<MainStepsPage attendant={true}/>} withoutFooter={true}/> ,
        },
        {
            path: 'revisao-vida-toda/:hash',
            element: <DefaultLayout children={<MainStepsPageFull/>} withoutFooter={true}/>,
        },
        {
            path: 'revisao-vida-toda/atendente/:hash',
            element: <DefaultLayout children={<AttendantSendDocsPage attendant={true}/>} withoutFooter={true}/>,
        },
        {
            path: 'indique',
            element: <DefaultLayout children={<ReferPage/>}/>,
        },
        {
            path: 'remover-conta',
            element: <DefaultLayout children={<RemoveAccountPage/>}/>,
        }
    ]);
