import React, { useEffect, useState, useContext } from "react";
import stepImage from "../../../assets/images/step1.png";
import ImageButtonComponent from "../../../components/ImageButtonComponent";
import {FaArrowLeft} from "react-icons/fa";


const AcceptDoc1 = (props) => {




    return (
        <div className={'p-4 position-relative'}>
            <button type="button" onClick={() => props.setStep(1)}
                    className={`btn d-flex align-items-center gap-1 rounded-pill btn-outline-dark`}>
                <FaArrowLeft size={12}/> <span style={{fontSize:'0.75rem'}}>Voltar</span>
            </button>

            <div>
                <h1 className={'text-center text-uppercase my-3'}>
                    Avisos Importantes!
                </h1>
                <div className={`d-flex px-2 px-md-4 gap-2`}>
                    <div dangerouslySetInnerHTML={{ __html:  props?.disclaimer }} />
                </div>
            </div>

            <div className={'row g-3  justify-content-center mt-3'}>
                <button type="button" onClick={() => props.setStep(3)} className={`col-12 col-md-7 col-lg-5 btn-reset p-2 fs-1 btn-ibrami-primary`}>Ciente e de Acordo</button>
            </div>

        </div>
    );
};

export default AcceptDoc1;
