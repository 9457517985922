import React, {useEffect, useState} from "react";

import {FaBalanceScale, FaCheck, FaLock, FaRegIdCard, FaRocket, FaUser} from "react-icons/fa";
import WelcomeStep from "./Components/WelcomeStep";
import EndStep from "./Components/EndStep";
import MultiStepProgress from "../../components/MultiStepProgress";
import DataConfirmationStep from "./Components/DataConfirmationStep";
import AcceptDoc1 from "./Components/AcceptDoc1";
import AcceptDocWithSelfie from "./Components/AcceptDocWithSelfie";
import SendDocsStep from "./Components/SendDocsStep";
import AcceptDoc2 from "./Components/AcceptDoc2";
import {Container, Image, Navbar, NavbarBrand} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {Images} from "../../assets/Images";
import {ProgressBarTracker} from "../../components/ProgressBarTracker";
import {BsFillFileEarmarkTextFill, BsFillQuestionCircleFill} from "react-icons/bs";
import {maskReal, scrollTo} from "../../Helpers/Helpers";
import axios from "axios";
import mobile from "../../assets/images/Mobile-bro.svg";
import congrats from "../../assets/images/congrats.png"
import SEO from "../../DefaultLayout/SEO";
import ReactGA from "react-ga4";
import {toast} from "react-toastify";

const MainStepsPageFull = (props) => {
    const [step, setStep] = useState(0);
    const [allSteps,setAllSteps] = useState([
        {
            title: 'Vamos lá!',
            icon: <FaRocket/>,
            id: 'START'
        },

        {
            title: 'Dados',
            icon: <FaUser/>,
            id: 'DATA'
        },

        {
            title: 'Termos',
            icon: <FaLock/>,
            id: 'TERMS'
        },

        {
            title: 'Ficha de Filiação',
            icon: <BsFillFileEarmarkTextFill/>,
            id: 'FILLIATION'
        },
        {
            title: 'Autorização',
            icon: <FaBalanceScale/>,
            id: 'AUTHORIZATION'
        },
        {
            title: 'Fim',
            icon: <FaCheck/>,
            id: 'END'
        }])
    const [dataUser, setDataUser] = useState({})
    const params = useParams()
    const [latLng, setLatLng] = useState(null);

    const handleChangeStep = (step) => {
        let activeStep = allSteps[step]?.id
        setStep(step)
        document.getElementById(activeStep).scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        });
        ReactGA.send({ hitType: "pageview", title:`${props.attendant? 'Atendente - Ibrami' : 'Ibrami'} | ${allSteps[step].title}` });

    }

    const checkDocPending = (affiliated) => {
        if (((affiliated.document_back.status === 'waiting_upload') || (affiliated.document_front.status === 'waiting_upload')) && allSteps.length === 6) {
            let aux = allSteps
            aux.splice(5,0,  {
                title: 'Último passo',
                icon: <FaRegIdCard/>,
                id: 'DOCS'
            },)
            setAllSteps(aux)
        }
    }

    const handleFinishForm = async (e) => {
        let person = dataUser?.registration.affiliated?.person


        let sendToApi = {
            contact_mail: person?.contact_mail,
            contact_mobile_phone: person?.contact_mobile_phone,
            address_cep: person?.address_cep,
            state_id: person?.state_id,
            address_city_id: person?.address_city_id,
            address_street: person?.address_street,
            address_number: person?.address_number,
            address_complement: person?.address_complement,
            address_district: person?.address_district,
            natural_nationality: person?.natural_nationality,
            natural_civil_status_id: person?.natural_civil_status_id,
            natural_gender: person?.natural_gender,
            evidence_lat: person?.evidence_lat,
            evidence_lng: person?.evidence_lng,
            evidence_accuracy: person?.evidence_accuracy,
            document_front: person?.document_front,
            document_back: person?.document_back,
            document_selfie: person?.document_selfie,
            ...e
        }

        try {
            let res = await axios.post(`inss-calc/registration/${params.hash}`, sendToApi);
            handleChangeStep(step === 1 ? 2 : 6);
            handleSaveForm({...e, natural_civil_status:res.data.object.affiliated.person.natural_civil_status, address_city:res.data.object.affiliated.person.address_city})

        } catch (e) {
            toast.error(e.response?.message || e.response?.data?.message || 'Parece que ocorreu um erro, tente novamente mais tarde!', {position:'top-right'})

        }
    }

    const handleSaveForm = (e) => {
        let aux = {
            ...dataUser,
        }
        aux.registration.affiliated.person = {
            ...aux.registration.affiliated.person,
            ...e
        }
        setDataUser(aux)
    }

    const getData = async () => {
        try {
            let {data: {object}} = await axios.get(`inss-calc/registration/${params?.hash}`)

            let {registration:{affiliated}} = object

            checkDocPending(affiliated)

            setDataUser(object)
        } catch (e) {
            let { response } = e
            if (response.data.message === 'Registro já foi finalizado') {
                setStep(6)
                toast.success('Registro já foi finalizado')
            }
            console.log(e)
        }

    }


    const getGeo = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(item => {
                setLatLng({lat: item.coords.latitude, lng: item.coords.longitude})
            }, (e) =>
            {
                console.log(e)
            },{maximumAge:10000, timeout:5000, enableHighAccuracy:true});
        }
    };

    useEffect(() => {
        getData()
        getGeo()
        scrollTo('full-steps')
    }, []);

    return (
        <>
            <SEO title={`${props.attendant? 'Atendente - Ibrami' : 'Ibrami'} | ${allSteps[step]?.title || 'Finalizado'}`}
                 description='Revisão da Vida Toda'
                 name='Revisão da Vida Toda'
                 type='article'
            />
            <Navbar expand="lg" className={'shadow-sm'} style={{backgroundImage: 'white', background: '#fff'}}>
                <Container>
                    <NavbarBrand>
                        <Link to={'/'}>
                            <img alt='logo' src={Images.ibramiLogo} style={{width: 240}}/>
                        </Link>

                    </NavbarBrand>
                </Container>
            </Navbar>
            <div className="container my-5" id={'full-steps'}>

                <div className={'text-gray-600'}>
                    <h1 className={'text-ibrami-primary '}>Revisão da Vida Toda</h1>
                    <p className={'text-muted ps-2'}>Termine seu cadastro e participe da ação coletiva do IBRAMI</p>
                </div>
                {((window.innerWidth > 2000))  ?
                    <div className={`card shadow`}>
                        <div className="card-body">
                            <div className={`py-4 text-center`}>
                                <div>
                                    <h1 className={`text-ibrami-primary`}>Parece que você está acessando pelo computador!</h1>
                                    <div className={` d-flex justify-content-center`}>
                                        <h5 className={'text-ibrami-secondary px-3 py-1 rounded-pill fw-normal'} style={{background:'#6b539329'}}>
                                            <span className={``} >Precisamos que você faça seu cadastro através do seu celular para maior segurança!</span>
                                        </h5>
                                    </div>

                                </div>
                                <img src={mobile} style={{maxWidth:350}} alt=""/>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="shadow rounded custom-progress" >
                            {step !== undefined && <ProgressBarTracker totalStep={allSteps.length} activeStep={step + 1}/>}
                            {step !== undefined &&<MultiStepProgress step={step} steps={allSteps}/>}
                            <div>
                                {step === 0 &&
                                    <div className="d-flex flex-grow-1 p-4 flex-column align-items-center w-100">



                                        <div className="text-center my-3">
                                            <h2 className={`text-muted mb-2`}> Parabéns, <strong className={'text-ibrami-secondary'}>{dataUser?.registration?.affiliated?.person.name}</strong> </h2>
                                            <div className="w-100 d-flex align-items-center p-1 justify-content-center">
                                                <Image src={congrats} style={{maxHeight: 300, borderRadius: 20, width: '100%', objectFit: 'contain'}} />
                                            </div>
                                            <h4 className='text-muted'>
                                                Você é elegível para um possível aumento de aposentadoria de <span style={{fontWeight: 'bold'}} className={'text-ibrami-secondary'}>R${maskReal(dataUser?.registration?.calculate.wage_original)}</span> para <span style={{fontWeight: 'bold'}} className={'text-ibrami-secondary'}>R${maskReal(dataUser?.registration?.calculate.last_wage)}</span>
                                            </h4>
                                            <h3 className=''>
                                               E ainda poderá receber <span style={{fontWeight: 'bold'}} className={'text-ibrami-secondary'}>R${maskReal(dataUser?.registration?.calculate.total_procedural)}</span> de retroativos*
                                            </h3>
                                        </div>
                                            <div className={`row w-100 justify-content-center`}>
                                                <button type="button"
                                                        onClick={() => {setStep(1)}}
                                                        className={`col-12 col-md-6 bg-ibrami-primary bg-opacity-90-hover btn-reset p-2 fs-1 rounded-pill text-white`}>
                                                    Próximo
                                                </button>
                                                <span style={{textAlign: 'center', color: 'gray', fontSize: 15,marginTop: 10}}>* Cálculo baseado nas informações contidas no CNIS e Carta de Concessão enviadas</span>
                                                <br/>
                                                <span style={{textAlign: 'center', color: 'gray', fontSize: 15,marginTop: 10}}>* O valor exato da causa dependerá do cálculo utilizado durante o julgamento da ação</span>
                                            </div>
                                    </div>
                                }
                                {step === 1 &&
                                    <DataConfirmationStep person={dataUser?.registration?.affiliated?.person}
                                                          setStep={(e) => handleChangeStep(2)}
                                                          handleSaveForm={(e) => handleFinishForm(e)}
                                    />
                                }

                                {step === 2 &&
                                    <AcceptDoc1 disclaimer={dataUser?.disclaimer}
                                                setStep={(e) => handleChangeStep(e)}
                                    />
                                }

                                {step === 3 &&
                                    <AcceptDoc2 registration_note={dataUser?.registration_note}
                                                person={dataUser?.registration?.affiliated?.person}
                                                setStep={(e) => handleChangeStep(e)}
                                    />
                                }
                                {step === 4 &&
                                    <AcceptDocWithSelfie authorization={dataUser?.authorization}
                                                         setStep={() => handleChangeStep(3)}
                                                         handleSaveForm={(e) => handleSaveForm(e)}
                                                         nextStep={() => handleChangeStep(5)}
                                                         procuration={dataUser?.procuration}
                                    />
                                }

                                {step === 5 &&
                                    <SendDocsStep docs={dataUser.registration} handleFinishForm={(e) => handleFinishForm(e)}
                                                  setStep={() => handleChangeStep(4)}/>
                                }

                                {step === 6 && <EndStep setStep={(e) => setStep(e)}/>}

                                <div className="border-top py-4  w-100 text-center">
                                    <a href={'https://ibrami.org/whatsapp-revisao'}
                                       className={'col-12 col-md-6 text-decoration-none d-flex align-items-center justify-content-center gap-1'}>
                                        <BsFillQuestionCircleFill color={'gray'} size={15}/>
                                        <span style={{fontSize: 15, color: "gray"}}>
                                            Está com dúvidas? <strong className="text-secondary text-decoration-underline">Clique aqui</strong>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>

    );
};

export default MainStepsPageFull;
