import React, { useEffect, useState, useContext } from "react";
import stepImage from "../../../assets/images/Audit-amico.svg";
import ImageButtonComponent from "../../../components/ImageButtonComponent";
import {BsFillQuestionCircleFill, BsTelephoneOutboundFill} from "react-icons/bs";
import {Link} from "react-router-dom";
import {FaHandsHelping} from "react-icons/fa";


const WelcomeStep = (props) => {
    const [step, setStep] = useState(0);

    useEffect(() => {}, []);

    return (
        <>
            <ImageButtonComponent title={'Vamos lá!'}
                                  description={'Vamos analisar e descobrir se o(a) senhor(a) tem direito a um aumento em sua aposentadoria!'}
                                  image={stepImage}
                                  buttonText={'Iniciar'}
                                  onClick={() => props.setStep(1)}
            />

        </>
    );
};

export default WelcomeStep;
