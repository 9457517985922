import React from "react";

export const ProgressBarTracker = ({activeStep, totalStep}) => {

	return (
		<>
			<div style={{height: 5}} className="progress w-100 p-0" id={'progress-bar'}>
				<div className="progress-bar progress-bar-striped progress-bar-animated"
					 role="progressbar"
					 style={{width: `${((100 / totalStep) * activeStep).toFixed(2)}%`}}
					 aria-valuemin="0"
					 aria-valuemax="100"
				/>
			</div>
		</>
	)
}
