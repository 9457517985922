import React, {useEffect, useState} from "react";
import {Container, Navbar, NavbarBrand} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Images} from "../../assets/Images";
import refer from "../../assets/images/refer.png";
import {ErrorMessageComponent} from "../../components/ErrorMessageComponent";
import {Controller, useForm} from "react-hook-form";
import NumberFormat from "react-number-format";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import stepImage from "../../assets/images/Launching-amico.svg";
import ImageButtonComponent from "../../components/ImageButtonComponent";
import axios from "axios";
import {scrollTo} from "../../Helpers/Helpers";
import SEO from "../../DefaultLayout/SEO";

const ReferPage = () => {

    const [step, setStep] = useState(0);

    const referSchema = yup.object().shape({
        name: yup.string().required('Informe seu nome'),
        contact_mobile_phone: yup.string().required('Informe seu número de celular').min(11, 'Insira um número válido'),
        referName: yup.string().required('Informe o nome da pessoa indicada'),
    });

    const {
        register,
        getValues,
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
        formState: {errors,}
    } = useForm({resolver: yupResolver(referSchema)});

    const handleSave = async (data) => {
        console.log(data)
        let aux = getValues();
        console.log(aux);
        let res = await axios.post(`inss-calc/lead-indication`, {
            indicator_person: aux.name,
            nominated_person: aux.referName,
            mobile_number: aux.contact_mobile_phone
        })
        setStep(1)
    }

    const onError = (e) => {
        console.log(e)
    }

    useEffect(() => {
        scrollTo('full-steps')
    }, []);

    return (
        <>
            <SEO title='Indique'
                 description='Conhece alguém que pode ter direito ao aumento de aposentadoria?'
                 name='Indique'
                 type='article'
            />
            <Navbar expand="lg" className={'shadow-sm'} style={{backgroundImage: 'white', background: '#fff'}}>
                <Container>
                    <NavbarBrand>
                        <Link to={'/'}>
                            <img alt='logo' src={Images.ibramiLogo} style={{width: 240}}/>
                        </Link>

                    </NavbarBrand>
                </Container>
            </Navbar>
            <div className="container my-5" id={'full-steps'}>

                {step === 0 &&
                <div className={'text-gray-600'}>
                    <h1 className={'text-ibrami-primary '}>Conhece alguém que pode ter direito
                        ao aumento de aposentadoria?</h1>
                    <p className={'text-muted ps-2'}>Preencha o formulário abaixo para informar-mos essa pessoa
                        deste benefício!</p>
                </div>
                }

                <div className={`card shadow`}>
                    <div className="card-body">
                        {step === 0 &&
                        <div className={`py-4 row g-2 justify-content-center`}>
                            <img src={refer} style={{maxWidth:400, maxHeight: 400}} alt=""/>
                            <div className={"col-12 col-md-12 col-lg-6"}>
                                <form id={'refer'} name={'Indicacao de amigo'} autoComplete={'off'}
                                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} onSubmit={handleSubmit(handleSave, onError)}>
                                    <div className={'my-3 row g-2 justify-content-center'}>

                                        <div className="col-12 col-lg-8 mb-2 ">
                                            <label htmlFor={'name'} className='col-form-label-lg'>Seu nome</label>
                                            <input
                                                className={`form-control custom-focus form-control-lg ${errors?.name?.message ? ' is-invalid' : 'form-control-solid'}`}
                                                type="text"
                                                id={'name'}
                                                name="name"
                                                placeholder="Nome"
                                                autoComplete="off"
                                                {...register('name')}
                                            />
                                            {errors?.name?.message &&
                                                <div className='mt-2'>
                                                    <ErrorMessageComponent message={errors?.name?.message}/>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-12 col-lg-8 mb-2">
                                            <label htmlFor={'referName'} className='col-form-label-lg'>Nome do(a) indicado(a)</label>
                                            <input
                                                className={`form-control custom-focus form-control-lg ${errors?.referName?.message ? ' is-invalid' : 'form-control-solid'}`}
                                                type="text"
                                                id={'referName'}
                                                name="referName"
                                                placeholder="Nome do(a) indicado(a)"
                                                autoComplete="off"
                                                {...register('referName')}
                                            />
                                            {errors?.referName?.message &&
                                                <div className='mt-2'>
                                                    <ErrorMessageComponent message={errors?.referName?.message}/>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-12 col-lg-8 mb-2">
                                            <label htmlFor="contact_mobile_phone" className='col-form-label-lg'>Celular do(a) indicado(a)</label>
                                            <Controller name='contact_mobile_phone'
                                                        control={control}
                                                        render={({field: {onChange, value}}) => (
                                                            <NumberFormat
                                                                className={`form-control custom-focus form-control-lg ${errors?.contact_mobile_phone?.message ? ' is-invalid' : 'form-control-solid'}`}
                                                                id={'contact_mobile_phone'}
                                                                name={'Celular'}
                                                                value={value}
                                                                onValueChange={(e) => onChange(e.value)}
                                                                placeholder='Celular do(a) indicado(a)'
                                                                format={'(##) #####-####'}
                                                                mask="_"
                                                            />
                                                        )}
                                            />

                                            {errors?.contact_mobile_phone?.message &&
                                                <div className='mt-2'>
                                                    <ErrorMessageComponent message={errors?.contact_mobile_phone?.message}/>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="row justify-content-center">
                                        <button className={'col-11 col-md-6 col-lg-4 btn-reset btn-ibrami-primary'} type="submit">Próximo</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                        }
                        {step === 1 &&
                            <ImageButtonComponent title={'Boa!'}
                                                  description={'Obrigado pela indicação! Em breve entraremos em contato com a pessoa indicada.'}
                                                  image={stepImage}
                                                  extra={<div className="border-top pt-4 mt-4 w-100 text-center">
                                                      <span style={{fontSize: 13, color: "gray"}}> Dúvidas? <strong className="text-secondary"> Fale conosco </strong></span>
                                                  </div>}
                            />
                        }
                    </div>
                </div>

            </div>
        </>

    );
};

export default ReferPage;
