import React, {useEffect} from 'react'
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import SEO from "../../DefaultLayout/SEO";

const Terms = () => {

    useEffect(() => {document.getElementById('root').scrollIntoView({behavior:'smooth'})}, [])
    return (
        <div>
            <SEO title='Termos de uso | Ibrami'
                 description='Termos de uso'
                 name='Termos de uso'
                 type='article'
            />
            <TermsOfUse/>
            <PrivacyPolicy/>
        </div>
    )
}

export default Terms
