import React from "react";

export const ErrorMessageComponent = ({message}) => {
	return (
		<div>
			<small className={`${message ? 'invalid-feedback d-block' : '' } mt-0`}>
				<span>{message}</span>
			</small>
		</div>
	)
}