import moment from "moment";

export const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView({behavior: 'smooth'})
}

export const getOnlyBase64 = (e) => {
    return e.split(',').pop()
}

export const maskPhone = (item) => {
    if(item) {
        if(item.substring(0,3) === '+55') {
            item = item.substring(3);
        } else if(item.substring(0,2) === '55') {
            item = item.substring(2);
        }
        var x = item.replace(/\D/g, '').match(/(\d{2})(\d{5})(\d{4})/);
        if(x && x[1] && x[2] && x[3]) {
            item = '(' + x[1] + ') ' + x[2] + '-' + x[3];
        }
        return item;
    } return null;
};

export const maskCpf = (value) => {
    if(!value) return "";
    let a = value.replace(/[^0-9]/g, '');
    switch (a.length) {
        case 0:
            a = '';
            break;
        case 1:
            a = a.charAt(0);
            break;
        case 2:
            a = a.charAt(0) + a.charAt(1);
            break;
        case 3:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2);
            break;
        case 4:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3);
            break;
        case 5:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4);
            break;
        case 6:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5);
            break;
        case 7:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6);
            break;
        case 8:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7);
            break;
        case 9:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7) + a.charAt(8);
            break;
        case 10:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7) + a.charAt(8) + '-' + a.charAt(9);
            break;
        case 11:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7) + a.charAt(8) + '-' + a.charAt(9) + a.charAt(10);
            break;
        default:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + '.' + a.charAt(6) + a.charAt(7) + a.charAt(8) + '-' + a.charAt(9) + a.charAt(10);

            break;
    }

    return a;
}


export const validateDate = (data) => {
    // Cria um objeto moment a partir da data e do formato
    const dataMoment = moment(data, 'DD/MM/YYYY', true);

    // Verifica se a data é válida e se está dentro do limite especificado
    return dataMoment.isValid() && dataMoment.isSameOrBefore(moment());
}

export const useQueryString = () => {
    const queryToObject = (search) => {

        let pairs = search.slice(1).split('&');


        let result = {};
        pairs.forEach(function(pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        const realResult = JSON.stringify(result);

        return realResult === "{\"\":\"\"}" ? {} : JSON.parse(realResult);
    }
    return {queryToObject}
}

export const maskReal = (a) => {
    if(a) {
        let aux = a;
        if(typeof a === 'string') {
            aux = a.replace(',','.');
        }
        if(isNaN(aux)) {
            aux = aux.replace(/\./g, '');
        }
        aux = Number(aux).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        return aux.toString();
    } else {
        return '---';
    }
};


export const validateMobilePhone = (item) => {
    if(item) {
        let auxPhone = item.replace(/\D/ig, '');

        if(auxPhone.length === 11) {
            let string = item.substring(2)
            const pattern = /\b(\d)\1+\b/;
            if (pattern.test(string)) {
                return false
            }

            if (['68', '82', '96', '92', '97', '71', '72', '73', '74', '75', '77', '85', '88', '61', '27', '28', '62', '64', '98', '99', '65', '66', '67', '31', '32', '33', '34', '35', '37', '38', '91', '93', '94', '83', '41', '42', '43', '44', '45', '46', '81', '87', '86', '89', '21', '22', '24', '84', '51', '53', '54', '55', '69', '95', '47', '48', '49', '11', '12', '13', '14', '15', '16', '17', '18', '19', '79', '63'].includes(auxPhone.substr(0, 2))) {
                if (['99', '98', '97', '96', '95', '94', '93', '92'].includes(auxPhone.substr(2,2))) {
                    return true;
                }
            }
        }
    }

    return false;
};