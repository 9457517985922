import React, { useEffect, useState, useContext } from "react";

import {MdCode, MdCreditCard, MdEmail, MdLock, MdPermContactCalendar, MdPhone} from "react-icons/md";
import {FaUser} from "react-icons/fa";
import InputField from "../../../components/InputField";
import {FormComponent} from "./FormComponent";
import {maskPhone} from "../../../Helpers/Helpers";

const MainStepProcedurePage = (props) => {

    return (
        <div className="p-4">


            {props?.affiliated ?
                <div className={`text-start text-md-center mt-3`}>
                    <h2 className={'text-ibrami-primary '}>Caro(a) atendente, preencha os dados de </h2>
                    <h2 className={'text-ibrami-secondary '}>{props?.affiliated} <br/>
                        {maskPhone(props?.phone)}</h2>

                </div>
                :
                <h1 className={'text-uppercase text-center'}>Preencha com seus dados</h1>
            }

            <FormComponent person={props?.person} nextStep={() => props.setStep(1)} handleSaveForm={(e) => props.handleSaveForm(e)}/>
        </div>

    );
};

export default MainStepProcedurePage;
