import React, { useEffect, useState, useContext } from "react";
import stepImage from "../../../assets/images/endStep.png";
import ImageButtonComponent from "../../../components/ImageButtonComponent";


const EndStep = (props) => {
    const [step, setStep] = useState(0);

    useEffect(() => {}, []);

    return (
        <ImageButtonComponent buttonColor={'success'}
                              titleColor={'success'}
                              title={'Parabéns!'}
                              description={'Fique atento ao seu WhatsApp, o IBRAMI lhe manterá informado sobre os próximos passos da nossa ação coletiva.'}
                              image={stepImage}
        />
    );
};

export default EndStep;
