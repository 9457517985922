import Webcam from "react-webcam";
import React, {useEffect, useRef, useState} from "react";
import {getOnlyBase64, scrollTo} from "../Helpers/Helpers";
import {CgClose} from "react-icons/cg";
import {FaArrowLeft} from "react-icons/fa";

const WebCamComponent = (props) => {
    const [imageSrc, setImgSrc] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [loading, setLoading] = useState(true);

    const webcamDocRef = useRef();
    const capture = React.useCallback(() => {
        const imageSrc = webcamDocRef.current.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamDocRef, setImgSrc]);

    const videoConstraints = {
        facingMode: { exact: props?.selfie ? "user" : "environment" },
        zoom: 0,
        focusDepth: 0,
        aspectRatio: 2,
    };

    const handleConfirmPhoto = () => {
        setPreviewImage(imageSrc)
        props.handleTakePicture(props.onlyBase64 ? getOnlyBase64(imageSrc) : imageSrc)
        scrollTo('full-steps')
        document.querySelector("body").classList.remove("hidden-scroll")
    }

    const handleCloseCamera = () => {
        console.log(`ckcui`)
        document.querySelector("body").classList.remove("hidden-scroll");
        props.closeCamera();
    }

    const handleTakeOtherPicture = () => {
        setImgSrc(null)
        setLoading(true)
    }

    useEffect(() => {
        document.querySelector("body").classList.add("hidden-scroll")
    }, []);

    return (
        <>
            {loading &&
                <div className={'position-fixed'} style={{inset:0, zIndex:1}}>
                    <div className={'position-absolute offcanvas-backdrop opacity-75'} style={{inset:0, zIndex:0}}/>

                    <div className={' d-flex position-absolute justify-content-center align-items-center'} style={{inset:0, zIndex:1}}>
                        <div>
                            <div className={'card'}>
                                <div className={'card-body'}>
                                    <div className={`d-flex align-items-center gap-3`}>
                                        <span className="spinner-border text-ibrami-primary"  role="status"></span>
                                        <span className="text-gray-800  fs-4 fw-semibold mt-1">
                                            Abrindo câmera...
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div>
                {imageSrc === null &&
                    <>
                        <div style={{position:'fixed', inset:0, zIndex:1, background: (!loading ? '#000' : ''), display:'flex', justifyContent:'center'}}>


                            {!loading &&
                                <>
                                    <div style={{position:'absolute', left:5,zIndex:2}} className={`p-2`} onClick={handleCloseCamera}>
                                        <FaArrowLeft style={{zIndex:1, color:'#fff', background:"black"}} className={`rounded-circle p-2`} size={40}/>
                                    </div>
                                    <div style={{position:'absolute', inset:0, display:"flex", justifyContent:'center', alignItems:'center'  }}>
                                        <div className={`frame-picture`}>
                                            <div className={`square`}/>
                                            <div className={`square`}/>
                                            <div className={`square`}/>
                                            <div className={`square`}/>
                                        </div>
                                    </div>
                                </>
                            }



                            <Webcam onUserMedia={() => setLoading(false)}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                    ref={webcamDocRef}
                            />

                            <div className={`${loading ? 'd-none' : 'd-flex'} w-100  justify-content-center`} style={{position:'fixed',left:0, bottom:24, right:0, zIndex:1}}>
                                <button type="button" onClick={() => capture()} className={"col-6 btn-reset btn-ibrami-secondary p-2 fs-1"}>Tirar Foto</button>
                            </div>
                        </div>
                    </>
                }


                {(imageSrc && previewImage === null)  &&
                    <div style={{position:"fixed", zIndex:1, height:'100%', inset:'0 0 15px',backgroundColor:'#000', display:'flex', justifyContent:'center'}}>
                        <div style={{position:'absolute', right:0}} className={`p-2`} onClick={handleCloseCamera}>
                            <CgClose style={{zIndex:1, color:'#fff', background:"black"}} className={`rounded-circle p-2`} size={40}/>
                        </div>
                        <img src={imageSrc}
                             alt=""
                             style={{maxHeight:'90%', objectPosition: "50% 50%", objectFit:'cover'}}
                        />

                        <div className={`row w-100 m-0`} style={{zIndex:1, position:"absolute", bottom:16}}>
                            <div className={`col-6 px-1`}>
                                <button className={'btn btn-outline-light rounded-pill p-1 fs-1 w-100'}
                                        onClick={() => handleTakeOtherPicture()}>Tirar outra</button>
                            </div>
                            <div className={`col-6 px-1`}>
                                <button className={' btn-reset btn-ibrami-primary w-100 p-1 fs-1'} onClick={() => handleConfirmPhoto()}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                }
                {(imageSrc && previewImage !== null) &&
                    <div className={`text-center pb-3`}>
                        <img src={previewImage}
                             alt={'selfie'}
                             className={'rounded-2'}
                             style={{maxHeight: 300, objectPosition: "50% 50%", objectFit:'cover'}}
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default WebCamComponent
