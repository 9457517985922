import React, {useEffect, useState, useContext, useRef} from "react";
import stepImage from "../../../assets/images/Recommendation letter-rafiki.svg";
import ImageButtonComponent from "../../../components/ImageButtonComponent";
import {DropzoneAttachmentsComponent} from "../../../components/DropzoneAttachmentsComponent";
import {FaCheck, FaPlay} from "react-icons/fa";

const Doc2Step = (props) => {
    const [step, setStep] = useState('INITIAL_STEP');
    const [document,setDocument] = useState(null);
    const [letter, setLetter] = useState(false);

    const sendBase64 = (e) => {
        props.saveData('document_letter_base64', e)
        setDocument({document_letter_base64:e})
    }


    const handleRemove = () => {
        setDocument(null)
    }

    useEffect(() => {
        setLetter(props.docsSent?.letter)
    }, []);

    return (

        <div className="container text-center">
            <div className={`${step === 'INITIAL_STEP' ? 'd-block' : 'd-none'}`}>
                <ImageButtonComponent title={'Insira a Carta de Concessão'}
                                      description={<span className={'text-gray-600 '}>A carta comprova que o benefício foi concedido e constam nela os detalhes da concessão</span>}
                                      image={stepImage}
                />

                <button type="button"
                        onClick={() => setStep('HOW_TO_GET')}
                        style={{marginTop: -10}}
                        className={`mb-4 bg-ibrami-secondary align-items-center bg-opacity-90-hover btn-reset px-4 py-2 rounded-pill text-white`}>
                    <FaPlay style={{marginRight: 4, marginTop: -3}}/>
                    <span>Como conseguir minha carta</span>
                </button>

                <div className={`px-4 row justify-content-center`}>
                    <div className={'col-12 col-md-4 col-lg-5'}>
                        <div className={`dropzone ${(letter) ? 'd-block' : 'd-none'}`} style={{backgroundColor:'#c8e6c9', borderColor:'#43a047'}} onClick={() => setLetter(false)}>
                            <div className={'d-flex align-items-center justify-content-center '}>
                                <FaCheck color={'#43a047'} className={'p-2 bg-white rounded-circle'} size={72}/>
                            </div>

                            <div>
                                <h2 style={{color:'#2e7d32'}}>
                                    O documento já foi enviado!
                                </h2>
                                <h5><strong>Clique aqui se deseja reenviar!</strong></h5>
                            </div>
                        </div>

                        <div className={`${(!letter ? 'd-block' : 'd-none')}`}>
                            <DropzoneAttachmentsComponent customText={'Clique aqui para enviar a Carta'}
                                                          attachments={(e) => sendBase64(e)}
                                                          onlyBase64={true}
                                                          styleSelectedFile={{minHeight:'auto', cursor:'pointer'}}
                                                          handleRemove={() => handleRemove()}
                                                          maxAttachments={1}
                            />
                        </div>

                    </div>
                </div>

                <div className="row justify-content-center my-4">
                    <button disabled={letter ? !letter : !document}
                            type="button"
                            onClick={() => props.setStep()}
                            className={`col-11 col-md-6 bg-ibrami-primary ${((letter ? !letter : !document) || props.loading) ? 'opacity-50' : ''} bg-opacity-90-hover btn-reset px-4 py-2 rounded-pill text-white`}>
                        {props.loading ?
                            <div className={'d-flex align-items-center justify-content-center gap-2'}>
                                <div className="spinner-border spinner-border-sm text-white" role="status"/>
                                <span>Enviando...</span>
                            </div> :
                            "Finalizar"
                        }
                    </button>
                </div>
            </div>

            <div className={`my-4 ${step === 'HOW_TO_GET' ? 'd-block' : 'd-none'}`}>
                <h3 className={`d-flex justify-content-center text-ibrami-secondary mb-3`} >
                    <span className={`px-2 py-1 rounded-2`} style={{background:'#6b53931c'}}>Como conseguir minha carta</span>
                </h3>
                <div className={`row`}>
                    <div className="col-12 px-3" style={{minHeight:650}}>
                        <iframe width="100%" height="100%"
                                className={` rounded-4`}
                                src="https://www.youtube.com/embed/awITf3pacJE"
                                title="Revisão da Vida Toda - Saiba como extrair o seu CNIS e Carta de Concessão do INSS"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </div>
                </div>
                <div>
                    <button className={`col-12 btn btn-outline-dark rounded-pill mt-4`} onClick={() => setStep('INITIAL_STEP')}>Voltar</button>
                </div>
            </div>
        </div>

    );
};

export default Doc2Step;
