import React, {useEffect, useRef, useState} from "react";
import backDoc from "../../../assets/images/document-back.jpeg";
import frontDoc from "../../../assets/images/document-front.jpeg";
import cnhDoc from "../../../assets/images/cnh.jpeg";
import {DropzoneAttachmentsComponent} from "../../../components/DropzoneAttachmentsComponent";
import {maskPhone, scrollTo} from "../../../Helpers/Helpers";
import {FaArrowLeft} from "react-icons/fa";

const SendDocsStep = (props) => {
    const documents = useRef({document_front: null, document_back: null, document_type: props.docs?.document_type || null});
    const [disableButton, setDisableButton] = useState(true);
    const [step, setStep] = useState(props.docs?.document_type? props.docs?.document_type : 'DOCUMENT_TYPE');
    const [loadingSentFiles, setLoadingSentFiles] = useState(false);
    const [frontPhoto, setFrontPhoto] = useState(props.docs?.full_url_document_front_image);
    const [backPhoto, setBackPhoto] = useState(props.docs?.full_url_document_back_image);

    const filesSent = (label, e) => {
        documents.current = {...documents.current, [label]: (e?.length === 0) ? null : e}
        console.log(documents.current)
        if ((documents.current.document_front || frontPhoto) && (documents.current.document_back || backPhoto)) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }

    const handleDocType = (type) => {
        documents.current.document_type = type
        setStep(type)
        scrollTo('docs')
    }

    const handleBack = () => {
        if (step === 'DOCUMENT_TYPE') {
            props.setStep()
        } else if (step === 'RG' || step === 'CNH') {
            documents.current.document_type = null
            setStep('DOCUMENT_TYPE')
            scrollTo('docs')
        }

        console.log(documents.current)
    }

    const handleFinish = () => {
        setLoadingSentFiles(true)
        setDisableButton(true)
        props.handleFinishForm(documents.current)
    }

    useEffect(() => {
        if(backPhoto || documents.current.document_back) {
            if(frontPhoto || documents.current.document_front) {
                setDisableButton(false)
            } else {
                setDisableButton(true);
            }
        } else {
            setDisableButton(true);
        }
    },[])

    return (

        <div className="container-fluid py-4" id={`docs`}>
            {!props?.affiliated &&<button type="button" onClick={() => handleBack()}
                    className={`btn d-flex align-items-center gap-1 rounded-pill btn-outline-dark mb-3`}>
                <FaArrowLeft size={12}/> <span style={{fontSize:'0.75rem'}}>Voltar</span>
            </button>}

            {(step === 'RG' || step === 'CNH') &&
                <>
                    <div className={`d-flex px-2 my-3 justify-content-center`}>
                        {backPhoto || frontPhoto ? <h2 className={' text-ibrami-primary rounded-pill  text-center'}> Confirme seus documentos para continuar </h2>:
                        <h2 className={' text-ibrami-primary rounded-pill  text-center'}>{props?.affiliated ? "Para confirmar a identidade, envie uma foto do documento que contenha foto do rosto e o CPF." : "Para confirmar sua identidade, tire uma foto do seu documento que contenha foto e seu CPF." }</h2>}
                    </div>


                    <div className={'row g-4 g-md-2 mb-3'}>
                        <div className={'col-12 col-md-6'} >
                            <div className={`container-fluid`} style={(frontPhoto && props.docs?.document_front_status === 'REJECTED')? {border: '1px solid red',textAlign: 'center', paddingBottom: 10, paddingTop: 10} : {border: '1px solid gainsboro',textAlign: 'center', paddingBottom: 10, paddingTop: 10}}>
                                {frontPhoto?
                                    <div>
                                        <span className={'fs-4 text-gray-600'}>Frente do Documento</span>
                                        <img src={frontPhoto} className={'w-100 mt-1 mb-3'}/>
                                    </div>
                                    :
                                    <DropzoneAttachmentsComponent attachments={(e) => filesSent('document_front', e)}
                                                                  dropCardImage={step === 'CNH' ? cnhDoc : frontDoc}
                                                                  maxAttachments={1}
                                                                  customText={'Frente do Documento'}
                                                                  titleSelectedImage={<span style={{backgroundColor:'#6b539326'}} className={`text-ibrami-secondary px-2 py-1 rounded-2`}>Frente do Documento</span>}
                                                                  multipleFiles={false}
                                                                  actionSheet={true}
                                                                  onlyImage={true}
                                                                  onlyBase64={true}
                                    />}
                                {(frontPhoto && props.docs?.document_front_status === 'REJECTED')&& <span style={{color: 'red'}}> Parece que essa imagem não foi aprovada, envie novamente</span>}
                                {(frontPhoto && props.docs?.document_front_status !== 'APPROVED') &&<button onClick={() => setFrontPhoto(null)} className={`btn-reset p-1 fs-4 btn-ibrami-secondary w-100 mt-3`}>
                                    Alterar
                                </button>}
                            </div>

                        </div>
                        <div className={'col-12 col-md-6'}>
                            <div className={`container-fluid`} style={(backPhoto && props.docs?.document_back_status === 'REJECTED')? {border: '1px solid red',textAlign: 'center', paddingBottom: 10, paddingTop: 10} : {border: '1px solid gainsboro',textAlign: 'center', paddingBottom: 10, paddingTop: 10}}>
                                {backPhoto?
                                    <div>
                                        <span className={'fs-4 text-gray-600'}>Verso do Documento</span>
                                        <img src={backPhoto} className={'w-100 mt-1 mb-3'}/>
                                    </div>
                                    :
                                    <DropzoneAttachmentsComponent attachments={(e) => filesSent('document_back',e)}
                                                                  dropCardImage={step === 'CNH' ? cnhDoc : backDoc}
                                                                  maxAttachments={1}
                                                                  customText={'Verso do Documento'}
                                                                  titleSelectedImage={<span style={{backgroundColor:'#6b539326'}} className={`text-ibrami-secondary px-2 py-1 rounded-2`}>Verso do Documento</span>}
                                                                  multipleFiles={false}
                                                                  actionSheet={true}
                                                                  onlyImage={true}
                                                                  onlyBase64={true}
                                    />}
                                {(backPhoto && props.docs?.document_back_status === 'REJECTED')&& <span style={{color: 'red'}}> Parece que essa imagem não foi aprovada, envie novamente</span>}
                                {(backPhoto && props.docs?.document_back_status !== 'APPROVED') &&<button onClick={() => setBackPhoto(null)} className={`btn-reset p-1 fs-4 btn-ibrami-secondary w-100 mt-3 `}>
                                   Alterar
                                </button>}
                            </div>
                        </div>
                    </div>

                    <div className={`row justify-content-center mt-5`}>


                        <div className={`col-12 col-md-6 col-lg-4 order-0 order-md-1`}>
                            <button disabled={disableButton} onClick={() => handleFinish()} className={`${disableButton ? 'opacity-50' : ''} btn-reset p-2 fs-1 btn-ibrami-primary w-100`}>
                                {loadingSentFiles ? 'Enviando...' : 'Finalizar'}
                            </button>
                        </div>
                    </div>
                </>
            }
            {step === 'DOCUMENT_TYPE' &&
                <>
                    <div className={`text-start text-md-center mt-3`}>
                        <h2 className={'text-ibrami-primary '}>
                            {props?.affiliated ? `Caro(a) atendente, insira abaixo os documentos de ` : "Qual documento abaixo (com CPF) o(a) senhor(a) tem em mãos?"}
                        </h2>
                        {props?.affiliated &&
                            <h2 className={'text-ibrami-secondary'}>
                                {props?.affiliated}
                                <br/>
                                {maskPhone(props?.phone)}
                            </h2>
                        }
                    </div>
                    <div className={`my-4`}>
                        <div className="container">
                            <div className={`row justify-content-center`}>
                                <div className={`col-12 col-md-6 col-lg-7 col-xl-5`}>
                                    <div className="border-0 card shadow" onClick={() => handleDocType('RG')}>
                                        <div className="card-body text-center d-flex justify-content-center align-items-center" style={{minWidth:270, minHeight:270}}>
                                            <div className={`py-2`} >
                                                <div>
                                                    <img src={frontDoc} alt="RG"  style={{maxHeight:170}}/>
                                                </div>

                                                <div className={`mt-3`}>
                                                    <h3 className={`fw-bold text-ibrami-primary mb-0`}>RG</h3>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`d-flex justify-content-center my-4`}>
                            <hr className={`flex-grow-1`} style={{maxWidth:300}}/>
                            <span className={`px-2`}>ou</span>
                            <hr className={`flex-grow-1`} style={{maxWidth:300}}/>
                        </div>

                        <div className="container">
                            <div className={`row justify-content-center`}>
                                <div className={`col-12 col-md-6 col-lg-7 col-xl-5`}>
                                    <div className="border-0 card shadow" onClick={() => handleDocType('CNH')}>
                                        <div className="card-body text-center d-flex justify-content-center align-items-center" style={{minWidth:270, minHeight:270}}>
                                            <div className={`py-2`}>
                                                <div>
                                                    <img src={cnhDoc} alt="RG" style={{maxHeight:130}}/>
                                                </div>

                                                <div className={`mt-4`}>
                                                    <h3 className={`fw-bold text-ibrami-primary mb-0`}>CNH</h3>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>

    );
};

export default SendDocsStep;
