import React, { useEffect, useState, useContext } from "react";
import stepImage from "../../../assets/images/endStep.png";
import ImageButtonComponent from "../../../components/ImageButtonComponent";


const EndStep = (props) => {
    const [step, setStep] = useState(0);

    useEffect(() => {}, []);

    return (
        <ImageButtonComponent buttonColor={'success'}
                              titleColor={'success'}
                              title={'Tudo certo!'}
                              description={<span className={'fs-5'}>Aguarde que vamos entrar em contato sobre a situação do seu reajuste o mais rápido possível!</span>}
                              image={stepImage}
        />
    );
};

export default EndStep;
