import React, { useEffect, useState, useContext } from "react";

const MultiStepProgress = (props) => {
    const [steps, setSteps] = useState(props.steps);

    return (
        <div className="container-fluid container-lg w-100">
            <div className="text-center mt-3 mb-4">
                <h2 className='text-ibrami-primary mb-2'> {steps[props.step]?.headerTitle} </h2>
                <span  className='text-muted' style={{fontSize: 15}}>
                       {steps[props.step]?.headerSubTitle}
                    </span>
            </div>
            <div className={'steps'}>
                <div className='d-flex flex-grow-1 label mb-4 mb-lg-0'>
                    {steps.map((item, index) =>
                        <div className='d-flex flex-grow-1 flex-column option' key={index} id={item.id}>
                            <div className='d-flex align-items-center'>
                                <div style={{height: 2}} className={props.step >= index? 'flex-grow-1 bg-ibrami-secondary' : 'flex-grow-1 bg-light'}/>
                                <div id={item?.id} style={{width:40, height: 40}} className={
                                    props.step >= index?
                                        'd-flex justify-content-center align-items-center bg-ibrami-secondary rounded-circle text-white'
                                        :
                                        'd-flex justify-content-center align-items-center bg-light rounded-circle text-white'}
                                >
                                    {item.icon}
                                </div>
                                <div style={{height: 2}} className={props.step >= index? 'flex-grow-1 bg-ibrami-secondary' : 'flex-grow-1 bg-light'}/>
                            </div>
                            <div className='mt-2 text-center font-weight-bold'>
                                <span className={props.step >= index? 'text-ibrami-primary' : 'text-gray-600'}>{item.title}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>


        </div>
    );
};

export default MultiStepProgress;
