import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider,} from "react-router-dom";
import {PathRouters} from "./routes/Routes";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/react";

process.env.NODE_ENV  === 'production' &&
Sentry.init({
    dsn: "https://ad4b5eec48f145a092841283773240ef@o516750.ingest.sentry.io/4505529314312192",
    debug: !process.env.NODE_ENV  === 'production',
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV  === 'production'? 'production' : 'development',
    beforeBreadcrumb(breadcrumb, hint) {
        return breadcrumb.category === "console" ? null : breadcrumb;
    },
    ignoreErrors: ["ResizeObserver loop limit exceeded"]
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={PathRouters}/>
);

