import React from 'react'
import {Container} from "react-bootstrap";
import {Images} from "../../../assets/Images";

const PrivacyPolicy = () => {

    return (
        <Container className='my-5 terms-of-use'>
            <div>
                <img src={Images.ibramiLogo} width={250} alt=""/>
            </div>
            <div className='mt-4'>
                <strong>
                    Instituto Brasileiro da Melhor Idade - IBRAMI
                </strong>
            </div>

            <div className='mt-4'>
                <div className='text-center fs-5'>
                    <strong>POLÍTICA DE PRIVACIDADE</strong>
                </div>
                <div className='section text-justify'>
                    <div>
                        A associação Instituto Brasileiro da Melhor Idade - IBRAMI (CNPJ: 12.099.971/0001-10), com sede em
                        Campo Grande / MS (“INSTITUTO”), detentora da Plataforma IBRAMI, entende como sensível e
                        relevante os registros eletrônicos e dados pessoais deixados por você (“Usuário”) na utilização desta
                        e de outros serviços e plataformas de sua propriedade (“Plataformas”), servindo a presente Política
                        de Privacidade (“Política”) para regular, de forma simples, transparente e objetiva, quais dados e
                        informações serão obtidos, assim como quando os mesmos poderão ser utilizados.
                    </div>
                    <div>
                        A presente Política se aplica especificamente ao IBRAMI, englobando todos os seus subsistemas.
                    </div>
                    <div>
                        Para melhor ilustrar a forma como realizamos o tratamento de dados, apresentamos um resumo de
                        nossa Política de Privacidade e Proteção de Dados Pessoais (“Política”):
                    </div>
                </div>
            </div>
            <div className='section mt-4'>
                <div>
                    <strong>QUADRO DE RESUMO</strong>
                </div>
                <div className='d-flex flex-column'>
                    <span>Agente de tratamento: Instituto Brasileiro da Melhor Idade - IBRAMI</span>
                    <span>CNPJ: 12.099.971/0001-10</span>
                    <span>Endereço: AV AFONSO PENA, 2362, SALA 02, CENTRO, na cidade de Campo Grande/MS</span>
                </div>
                <div>
                    Esta Política poderá ser atualizada, a qualquer tempo, pelo IBRAMI, mediante aviso no site e/ou por
                    e-mail, se o Usuário tiver optado por receber comunicações do IBRAMI.
                </div>
            </div>
            <div className='section mt-4'>
                <strong>1. QUAIS DADOS UTILIZAMOS</strong>
                <div>
                    O IBRAMI poderá coletar as informações inseridas ativamente pelo Usuário no momento do cadastro
                    e, ainda, informações coletadas automaticamente quando da utilização do IBRAMI e seus
                    subsistemas, bem como da rede, como, por exemplo, identificação do local utilizado, IP com data e
                    hora da conexão, entre outras.
                </div>
                <div>
                    Há, assim, o tratamento de dois tipos de dados pessoais: (i) aqueles fornecidos pelo próprio Usuário;
                    e (ii) aqueles coletados automaticamente, através de convênios e acordos realizados pelo e com o
                    Poder Público.
                </div>
                <div>
                    (i) Informações fornecidas pelo Usuário: o IBRAMI coleta todas as informações inseridas ativamente
                    pelo Usuário em sua plataforma, tais como e não se limitando a nome completo, CPF, endereço,
                    e-mail, número de telefone celular, gênero, data de nascimento, cidade e estado, regime
                    previdenciário, valor do benefício e dados de saúde, quando do preenchimento de formulários no
                    IBRAMI pelo Usuário. O IBRAMI fará uso dessas informações para viabilizar a comprovação ao Poder
                    Público acerca da utilização dos dados disponíveis em sua base, possibilitando, com isso, a auditoria
                    e comprovação de sua utilização, em todo o ciclo de usabilidade.
                </div>
                <div>
                    (ii) Dados coletados automaticamente: o IBRAMI também coleta uma série de informações de modo
                    automático, tais como, mas não se limitando a: características do dispositivo de acesso, do
                    navegador, IP (com data e hora), porta lógica de origem do IP, informações sobre cliques, páginas
                    acessadas, as páginas seguintes acessadas após a saída das Páginas, ou qualquer termo de procura
                    digitado nos sites ou em referência a estes, dentre outros. Para tal coleta, o IBRAMI fará uso de
                    algumas tecnologias padrões, como cookies, pixel tags, beacons e local shared objects, que são
                    utilizadas com o propósito de melhorar a experiência de navegação do Usuário no IBRAMI, de acordo
                    com seus hábitos e suas preferências.
                </div>
                <div>
                    É possível desabilitar, por meio das configurações de seu navegador de internet, a coleta automática
                    de informações por meio de algumas tecnologias, como cookies e caches, bem como em nosso
                    próprio website, especificamente quanto aos cookies. No entanto, o Usuário deve estar ciente de
                    que, se desabilitadas estas tecnologias, alguns recursos oferecidos pelo site, que dependem do
                    tratamento dos referidos dados, poderão não funcionar corretamente.
                </div>
                <div>
                    Além disso, o IBRAMI poderá promover pesquisas de satisfação e de preferência durante sua
                    utilização, coletando estas informações para melhorar cada dia mais os serviços, para compreender
                    melhor as suas preferências e avaliar a qualidade e suas impressões sobre as soluções ofertadas,
                    bem como para fins estatísticos e publicitários. Para esta última hipótese, o IBRAMI disponibiliza
                    funcionalidades para que o Usuário autorize (ou não) o tratamento de seus dados, sendo certo que
                    tal aceitação não é condição para cadastramento do Usuário.
                </div>
                <div>
                    As informações coletadas poderão ser compartilhadas pelo IBRAMI nas seguintes hipóteses: (i) com
                    demais órgãos públicos e parceiros, quando forem necessárias para a adequada prestação dos
                    serviços objeto de suas atividades; (ii) para proteção dos interesses do IBRAMI em qualquer tipo de
                    conflito; (iii) mediante decisão judicial ou requisição de autoridade competente.
                </div>
                <div>
                    Ainda, no âmbito interno esclarecemos que suas informações também poderão ser compartilhadas
                    com empresas provedoras de infraestrutura tecnológica e operacional necessária para as atividades
                    do IBRAMI, como intermediadoras de pagamento e provedores de serviço de armazenamento de
                    informações.
                </div>
                <div>
                    O Usuário poderá acessar, atualizar e adicionar seus dados, bem como poderá solicitar a exclusão dos
                    seus dados coletados pelo IBRAMI, através do formulário disponível em sua área logada no sistema e
                    também através do e-mail contato@ibrami.org. Iremos nos esforçar para respondê-lo no menor
                    tempo possível, respeitando-se os prazos de guarda estabelecidos pela legislação.
                </div>
            </div>
            <div className='section mt-4'>
                <div>
                    <strong>2. COMO UTILIZAMOS OS DADOS</strong>
                </div>
                <div>
                    As informações coletadas pelo IBRAMI têm como finalidade a gestão, administração, prestação,
                    ampliação e melhoramento do IBRAMI tanto ao usuário, quanto perante este e o Poder Público.
                </div>
                <div>
                    As informações coletadas poderão, ainda, ser utilizadas tanto para fins publicitários, como para o
                    envio de informações de novas campanhas e benefícios do IBRAMI, bem como a divulgação de eventos, ou para a realização de pesquisa de satisfação, mediante o consentimento do titular para
                    tal.
                </div>
                <div>
                    O IBRAMI poderá centralizar as informações coletadas, as quais poderão ser utilizadas em seus
                    respectivos subsistemas, respeitadas as finalidades ora dispostas e o consentimento do Usuário.
                </div>
                <div>
                    Caso não deseje mais receber informativos publicitários do IBRAMI, ou de seus respectivos produtos,
                    soluções e plataformas, a qualquer momento o Usuário pode contatar o IBRAMI através formulário
                    disponível em sua área logada no sistema e também através do e-mail contato@ibrami.org.
                </div>
                <div>
                    Nestes casos, o tratamento de dados é autorizado pelo inciso III do artigo 7º, bem como do inciso IV,
                    §1º do art. 26, ambos da Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados – LGPD”).
                </div>

            </div>
            <div className='section mt-4'>
                <div>
                    <strong>
                        3. COMO UTILIZAMOS OS COOKIES
                    </strong>
                </div>
                <div>
                    Cookies são arquivos ou informações que podem ser armazenadas em seus dispositivos, quando
                    você visita o IBRAMI. Geralmente, um cookie contém o nome do site que o originou, seu tempo de
                    vida e um valor, que é gerado aleatoriamente.
                </div>
                <div>
                    O IBRAMI utiliza cookies para facilitar o uso e melhor adaptar o IBRAMI aos seus interesses e
                    necessidades, bem como para compilarmos informações sobre a utilização de nossos subsistemas,
                    sites e serviços, auxiliando a melhorar suas estruturas e seus conteúdos. Os cookies também podem
                    ser utilizados para acelerar suas atividades e experiências futuras nas Páginas.
                </div>

            </div>
            <div className='section mt-4'>
                <div>
                    <strong>
                        4. COMO MANTEMOS OS DADOS SEGUROS
                    </strong>
                </div>
                <div>
                    O IBRAMI armazenará as informações coletadas pelo IBRAMI em servidores próprios ou por ela
                    contratados.
                </div>
                <div>
                    O IBRAMI utiliza os meios razoáveis de mercado e legalmente requeridos para preservar a
                    privacidade dos dados coletados através do IBRAMI. Desta forma, adota as seguintes precauções, em
                    observância às diretrizes sobre padrões de segurança estabelecidas no Decreto nº 8.771/2016, bem
                    como aquelas recomendadas pela Associação Brasileira de Normas Técnicas - ABNT, tais como:
                </div>
                <div>
                    <strong>i.</strong> O IBRAMI utiliza os métodos padrão e de mercado para criptografar e anonimizar os dados
                    coletados;
                </div>
                <div>
                    <strong>ii.</strong> O IBRAMI possui proteção contra acesso não autorizado a seus sistemas;
                </div>
                <div>
                    <strong>iii.</strong> O IBRAMI somente autoriza o acesso de pessoas previamente estabelecidas ao local onde são
                    armazenadas as informações coletadas;
                </div>
                <div>
                    <strong>iv.</strong> Aqueles que entrarem em contato com as informações deverão se comprometer a manter sigilo
                    absoluto. A quebra do sigilo acarretará responsabilidade civil e o responsável será responsabilizado
                    nos moldes da legislação brasileira; e
                </div>
                <div>
                    <strong>v.</strong> Manutenção do inventário indicando momento, duração, identidade do funcionário, ou do
                    responsável pelo acesso e o arquivo objeto, com base nos registros de conexão e de acesso a
                    aplicações, conforme determinado no artigo 13 do Decreto nº 8.771/2016.
                </div>
                <div>
                    O IBRAMI adota os melhores esforços, no sentido de preservar a privacidade dos dados dos Usuários.
                    Entretanto, nenhum site é totalmente seguro e o IBRAMI não pode garantir integralmente que todas
                    as informações que trafegam no IBRAMI não sejam alvo de acessos não autorizados perpetrados por
                    meio de métodos desenvolvidos para obter informações de forma indevida. Por esse motivo, nós
                    incentivamos os Usuários a tomar as medidas apropriadas para se proteger, como, por exemplo,
                    mantendo confidenciais todos os nomes de usuário e senhas, bem como utilizando softwares
                    seguros de armazenamento de senhas e informações sigilosas.
                </div>

            </div>
            <div className='section mt-4'>
                <div>
                    <strong>
                        5. RETENÇÃO DAS INFORMAÇÕES COLETADAS
                    </strong>
                </div>
                <div>
                    As informações coletadas pelo IBRAMI serão automaticamente excluídas de seus servidores quando
                    deixarem de ser úteis para os fins para os quais foram coletadas, ou quando o usuário solicitar a
                    eliminação de seus dados pessoais.
                </div>
                <div>
                    Sem prejuízo, as informações poderão ser conservadas para cumprimento de obrigação legal ou
                    regulatória, transferência a terceiro – desde que respeitados os requisitos de tratamento de dados –
                    e uso exclusivo do IBRAMI, vedado seu acesso por terceiro, desde que anonimizadas ou
                    pseudonimizadas.
                </div>

            </div>
            <div className='section mt-4'>
                <div>
                    <strong>
                        6. SEUS DIREITOS
                    </strong>
                </div>
                <div>
                    <strong>6.1</strong> Direitos Básicos do Usuário. O Usuário poderá solicitar a confirmação da existência tratamento de
                    Dados Pessoais, além da exibição de seus Dados Pessoais, por meio do nosso Canal de Atendimento
                </div>
                <div>
                    Enquanto o Usuário mantiver conta na plataforma, a correção dos seus Dados Pessoais deverá ser
                    realizada diretamente na respectiva área logada.
                </div>
                <div>
                    Enquanto o Usuário mantiver conta ativa na plataforma, somente os pedidos de confirmação de
                    existência de dados pessoais relacionados a outras espécies de relacionamento entre o Usuário e o
                    IBRAMI, a exemplo de compartilhamento de dados com empresas ofertantes de vagas e demais
                    vantagens, serão direcionados ao Encarregado de Dados Pessoais (Data Privacy Officer, ou “DPO”).
                </div>
                <div>
                    <strong>6.2</strong> Limitação, oposição e exclusão de dados. Pelos Canais de Atendimento, o Usuário poderá
                    também:
                </div>
                <div>
                    a) Manifestar sua oposição e/ou revogar o consentimento quanto ao uso de seus Dados Pessoais; ou
                </div>
                <div>
                    b) Solicitar a exclusão de seus Dados Pessoais que tenham sido coletados pelo IBRAMI.
                    Se o Usuário retirar seu consentimento para finalidades fundamentais ao funcionamento adequado
                    do site e dos serviços, os serviços tornar-se-ão indisponíveis.
                </div>
                <div>
                    Caso o Usuário solicite a exclusão de seus Dados Pessoais, situação em que imediatamente perderá
                    acesso ao IBRAMI, pode ocorrer que os Dados precisem ser mantidos por período superior ao pedido
                    de exclusão, nos termos do artigo 16 da Lei Geral de Proteção de Dados Pessoais, para (i)
                    cumprimento de obrigação legal ou regulatória, (ii) estudo por órgão de pesquisa, e (iii) transferência
                    a terceiro (respeitados os requisitos de tratamento de dados dispostos na mesma Lei). Em todos os
                    casos mediante a anonimização dos Dados Pessoais, desde que possível.<br/>
                    Findos o prazo de manutenção e a necessidade legal, os Dados Pessoais serão excluídos com uso de
                    métodos de descarte seguro, ou utilizados de forma anonimizada para fins estatísticos.
                </div>
                <div>
                    Parte destes direitos poderá ser exercida diretamente pelo Usuário, a partir da gestão de
                    informações sobre a conta, na página de seus dados. As demais alterações dependerão do envio de
                    solicitação para posterior avaliação e adoção de demais providências pelo IBRAMI.
                </div>
                <div>
                    O IBRAMI empreenderá todos os esforços para atender tais pedidos no menor espaço de tempo
                    possível. No entanto, mesmo em caso de requisição de exclusão, será respeitado o prazo de
                    armazenamento mínimo de informações de usuários de aplicações de Internet, determinado pela
                    legislação brasileira.
                </div>

                <div>
                    Em caso de requisição de cancelamento de conta a pedido do Usuário, a qual esteja pendente de
                    justificativa exigida para a visualização do dado da pessoa física solicitada, o IBRAMI poderá
                    concluí-la somente após a providência adotada pelo Usuário.
                </div>

                <div>
                    Dados de contato do Encarregado de Dados: Rafael de Andrade Silva <br/>
                    Endereço físico de contato: <span style={{color:'#0d6efd', textDecoration:"underline"}}>rafael@ibrami.org</span>
                </div>

            </div>
            <div className='section mt-4'>
                <div>
                    <strong>
                        7. LEGISLAÇÃO E FORO
                    </strong>
                </div>
                <div>
                    Esta Política será regida, interpretada e executada de acordo com as Leis da República Federativa do
                    Brasil, especialmente a Lei nº 13.709/2018, independentemente das Leis de outros estados ou
                    Países, sendo competente o foro de domicílio do Usuário para dirimir qualquer dúvida decorrente
                    deste documento.
                </div>
                <div>
                    <strong>Versão: 25 de novembro de 2022.</strong>
                </div>
            </div>

        </Container>
    )
}

export default PrivacyPolicy
