import old1 from '../assets/images/old1.png'
import old5 from '../assets/images/old5.jpg'
import old10 from '../assets/images/old10.png'
import ibramiLogo from '../assets/images/ibrami7.png'
import qrcode from '../assets/images/qrcode.jpeg'
import gplay_icon from '../assets/images/gplay.png'
import phone1 from '../assets/images/split1.png'
import phone2 from '../assets/images/split2.png'
import phone3 from '../assets/images/split3.png'
import phone4 from '../assets/images/split4.png'
import socialmediaphone from '../assets/images/redesocial.png'
import news from '../assets/images/noticiais.png'
import chat from '../assets/images/bate-bapogrupos.png'
import findpeoples from '../assets/images/buscarpessoas.png'
import boleto from '../assets/images/bill-8851.svg'

export const Images = {
  old1,
  old5,
  old10,
  ibramiLogo,
  qrcode,
  gplay_icon,
  phone1,
  phone2,
  phone3,
  phone4,
  socialmediaphone,
  news,
  chat,
  boleto
}
